import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Cancelbtn from "src/components/common/Cancelbtn";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { chkRoutePerm } from "src/services/Helper";
import * as Messages from "src/constants/Messages/index";
import GoogleAddressBox from "src/components/GoogleAddressBox";
import { Close } from "@material-ui/icons";
import { Link } from "react-router-dom";

const unit = [
  {
    id: 0,
    value: 0,
    label: "Hour",
  },
  {
    id: 1,
    value: 1,
    label: "Day",
  },
  {
    id: 2,
    value: 2,
    label: "Week",
  },
  {
    id: 3,
    value: 3,
    label: "Month",
  },
  {
    id: 4,
    value: 4,
    label: "Year",
  },
];

const SettingsSchema = Yup.object().shape({
  tax_rate: Yup.string().required(Messages.REQUIRED),
  greate_western_email: Yup.string()
    .required(Messages.REQUIRED)
    .email(Messages.INVALID_EMAIL),
  mile_charges: Yup.number().required(Messages.REQUIRED),
  free_miles: Yup.number().required(Messages.REQUIRED),
  api_key: Yup.string().required(Messages.REQUIRED),
  api_secret: Yup.string().required(Messages.REQUIRED),
  facebook_link: Yup.string().required(Messages.REQUIRED),
  linkedIn_link: Yup.string().required(Messages.REQUIRED),
  instagram_link: Yup.string().required(Messages.REQUIRED),
  stripe_api_key: Yup.string().required(Messages.REQUIRED),
  stripe_api_secret: Yup.string().required(Messages.REQUIRED),
  prequestionnaire_remider_duration: Yup.number().required(Messages.REQUIRED),
  prequestionnaire_unit: Yup.string().required(Messages.REQUIRED),
  postquestionnaire_remider_duration: Yup.number().required(Messages.REQUIRED),
  postquestionnaire_unit: Yup.string().required(Messages.REQUIRED),
});

const SettingsForm = ({
  formValues,
  fetchSetting,
  updateSetting,
  ...props
}) => {
  const chkRoutPerm = chkRoutePerm("/app/settings/edit", props.authuser);

  const [Address, setAddress] = useState(
    formValues.admin_address !== undefined ? formValues.admin_address : {}
  );

  // console.log(formValues.admin_address);

  const [fileError, setfileError] = useState("");
  const [fileRequiredError, setFileRequiredError] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const [selectedFile, setSelectedFile] = useState(false);

  const handleUploadClick = async (e, setFieldValue) => {
    // console.log(e.target.files[0]);
    setfileError("");
    setFileRequiredError("");
    setFileUrl("");

    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    let fileext = files[0].name.split(".");
    if (fileext[fileext.length - 1] !== "pdf") {
      //if (!files[0].name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      setfileError("This file is not allowed, please upload pdf file.");
      setSelectedFile(false);

      // return false;
    } else {
      setFileUrl(URL.createObjectURL(files[0]));
      createImage(files[0], setFieldValue);
      setSelectedFile(true);
      setfileError("");
    }
  };

  const createImage = (file, setFieldValue) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setFieldValue("file_name", e.target.result);
      setFieldValue("file", file);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={SettingsSchema}
      //onSubmit={updateProfile}
      onSubmit={async (values, actions) => {
        const { file_name, file, ...updatedValues } = values;

        // await updateSetting(values);
        // console.log(Address);
        // return;
        await updateSetting({
          ...updatedValues,
          // admin_address: Address.name + ", " + Address.address_line_1,
          admin_address:
            Address.name === undefined
              ? Address
              : // : Address.name + ", " + Address.address_line_1,
                Address.address_line_1,

          house_name:
            Address.house_name !== undefined ? Address.house_name : "",
          country: Address.country,
          state: Address.state,
          city: Address.city,
          zip: Address.zip,
          long: Address.long,
          lat: Address.lat,
          gpl_pdf_file: values.file_name,
        });
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Card>
                    <CardHeader
                      subheader="Tax rate applied on packages."
                      title="Tax Rate"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Tax Rate (%)"
                            name="tax_rate"
                            onBlur={handleBlur}
                            value={values.tax_rate}
                            type="text"
                            required
                            onChange={(e) => {
                              e.preventDefault();
                              const { value } = e.target;
                              const regex = /^\d*(\.\d{0,2})?$/;
                              if (regex.test(value.toString())) {
                                setFieldValue("tax_rate", value);
                              }
                            }}
                            margin="normal"
                            variant="outlined"
                            error={Boolean(touched.tax_rate && errors.tax_rate)}
                            helperText={touched.tax_rate && errors.tax_rate}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Card>
                    <CardHeader
                      subheader="Extra Miles Setting"
                      title="Extra Miles Setting"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Mile Charges ($)"
                            name="mile_charges"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.mile_charges}
                            type="number"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.mile_charges && errors.mile_charges
                            )}
                            helperText={
                              touched.mile_charges && errors.mile_charges
                            }
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Free Miles"
                            name="free_miles"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.free_miles}
                            type="number"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.free_miles && errors.free_miles
                            )}
                            helperText={touched.free_miles && errors.free_miles}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <GoogleAddressBox
                            name="admin_address"
                            label="Office Address"
                            placeholder="Office Address (Extra miles will be counted form this address.)"
                            address={values.admin_address}
                            setAddress={setAddress}
                            // setFieldValue={setFieldValue}
                            required
                          />

                          {/* <FormControl fullWidth> */}
                          {/* <InputLabel
                              htmlFor="office-address"
                              shrink={!!values.admin_address}
                            >
                              Office Address
                            </InputLabel> */}
                          {/* <TextField
                              name="address"
                              fullWidth
                              color="primary"
                              label="Office Address"
                              placeholder="Office Address (Extra miles will be counted form this address.)"
                              margin="normal"
                              variant="outlined"
                              required
                              InputProps={{
                                inputComponent: ({
                                  inputRef,
                                  onFocus,
                                  onBlur,
                                  ...props
                                }) => (
                                  <Autocomplete
                                    {...props}
                                    apiKey={
                                      "AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"
                                    }
                                    onPlaceSelected={(place) => {
                                      place.address_components.length > 0 &&
                                        place.address_components.map(
                                          (address_component) => {
                                            if (
                                              address_component.types[0] ===
                                              "administrative_area_level_1"
                                            ) {
                                              setFieldValue(
                                                "state",
                                                address_component.long_name
                                              );
                                            } else if (
                                              address_component.types[0] ===
                                              "locality"
                                            ) {
                                              setFieldValue(
                                                "city",
                                                address_component.long_name
                                              );
                                            } else if (
                                              address_component.types[0] ===
                                              "postal_code"
                                            ) {
                                              setFieldValue(
                                                "zip",
                                                address_component.long_name
                                              );
                                            } else if (
                                              address_component.types[0] ===
                                              "country"
                                            ) {
                                              setFieldValue(
                                                "country",
                                                address_component.long_name
                                              );
                                            }
                                          }
                                        );

                                      setFieldValue(
                                        "admin_address",
                                        place.formatted_address
                                      );
                                      setFieldValue(
                                        "long",
                                        place.geometry.location.lng()
                                      );
                                      setFieldValue(
                                        "lat",
                                        place.geometry.location.lat()
                                      );
                                    }}
                                    options={{
                                      types: ["establishment"],
                                    }}
                                    defaultValue={values.admin_address}
                                  />
                                ),
                              }}
                            /> */}
                          {/* </FormControl> */}
                          {/*<GoogleAutocompleteAddress
                            setAddressData = {setFieldValue}
                          />*/}
                          {/*<TextField
                            fullWidth
                            label="Address1"
                            name="address1"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address1}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.address1 &&
                                errors.address1
                            )}
                            helperText={
                              touched.address1 &&
                              errors.address1
                            }
                          />*/}
                        </Grid>
                        {/*<Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Address2"
                            name="address2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address2}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.address2 &&
                                errors.address2
                            )}
                            helperText={
                              touched.address2 &&
                              errors.address2
                            }
                          />
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <TextField
                            fullWidth
                            label="City"
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            disabled={true}
                            error={Boolean(
                              touched.city &&
                                errors.city
                            )}
                            helperText={
                              touched.city &&
                              errors.city
                            }
                          />
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <TextField
                            fullWidth
                            label="State"
                            name="state"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.state}
                            disabled={true}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.state &&
                                errors.state
                            )}
                            helperText={
                              touched.state &&
                              errors.state
                            }
                          />
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <TextField
                            fullWidth
                            label="Country"
                            name="country"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.country}
                            disabled={true}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.country &&
                                errors.country
                            )}
                            helperText={
                              touched.country &&
                              errors.country
                            }
                          />
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <TextField
                            fullWidth
                            label="Zip"
                            name="zip"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.zip}
                            disabled={true}
                            type="text"
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.zip &&
                                errors.zip
                            )}
                            helperText={
                              touched.zip &&
                              errors.zip
                            }
                          />
                        </Grid>*/}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Card>
                    <CardHeader
                      // subheader="Greate Western Email."
                      title="Great Western Email"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Great Western Email"
                            name="greate_western_email"
                            onBlur={handleBlur}
                            value={values.greate_western_email}
                            type="email"
                            required
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.greate_western_email &&
                                errors.greate_western_email
                            )}
                            helperText={
                              touched.greate_western_email &&
                              errors.greate_western_email
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12} style={{ marginBottom: "15px" }}>
                  <Card>
                    <CardHeader
                      subheader="Social Link Setting"
                      title="Social Links"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            required
                            name="facebook_link"
                            label="Facebook"
                            type="url"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.facebook_link}
                            error={Boolean(
                              touched.facebook_link && errors.facebook_link
                            )}
                            margin="normal"
                            helperText={
                              touched.facebook_link && errors.facebook_link
                            }
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            name="linkedIn_link"
                            label="Linkedin"
                            type="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.linkedIn_link}
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.linkedIn_link && errors.linkedIn_link
                            )}
                            helperText={
                              touched.linkedIn_link && errors.linkedIn_link
                            }
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            name="twitter_link"
                            label="Twitter"
                            margin="normal"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.twitter_link}
                            required
                            type="url"
                            variant="outlined"
                            error={Boolean(
                              touched.twitter_link && errors.twitter_link
                            )}
                            helperText={
                              touched.twitter_link && errors.twitter_link
                            }
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            label="Instagram"
                            name="instagram_link"
                            type="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.instagram_link}
                            required
                            margin="normal"
                            variant="outlined"
                            error={Boolean(
                              touched.instagram_link && errors.instagram_link
                            )}
                            helperText={
                              touched.instagram_link && errors.instagram_link
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              {/* ----GPL---- */}
              <Grid container spacing={3}>
                <Grid item md={12} xs={12} style={{ marginBottom: "15px" }}>
                  <Card>
                    <CardHeader
                      // subheader="General Price"
                      title="General Price List"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            name="gpl_pdf_link"
                            label="General Price Link"
                            type="url"
                            variant="outlined"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.gpl_pdf_link}
                            error={Boolean(
                              touched.gpl_pdf_link && errors.gpl_pdf_link
                            )}
                            margin="normal"
                            helperText={
                              touched.gpl_pdf_link && errors.gpl_pdf_link
                            }
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <input
                            type="file"
                            name="gpl_pdf_file"
                            // value={values.gpl_pdf_file}
                            style={{ display: "none" }}
                            id="contained-button-file"
                            onChange={(event) => {
                              handleUploadClick(event, setFieldValue);
                            }}
                          />

                          <Button color="primary" variant="contained">
                            <label
                              htmlFor="contained-button-file"
                              style={{
                                marginBottom: "0px",
                              }}
                            >
                              Upload Document
                            </label>
                          </Button>
                          {values?.gpl_pdf_file && !selectedFile && (
                            <span style={{ margin: "8px" }}>
                              <a
                                href={values.gpl_pdf_file}
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#007bff",
                                }}
                              >
                                File Selected
                              </a>
                            </span>
                          )}
                          {selectedFile && (
                            <span style={{ margin: "8px", color: "green" }}>
                              File successfully selected
                            </span>
                          )}
                          <FormHelperText className="Mui-error">
                            {fileError !== "" ? fileError : fileRequiredError}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Card>
                <CardHeader
                  // subheader="Payment Gateway"
                  title="Payment Gateway Setting"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <FormLabel style={{ margin: "0 10px 0 0" }}>
                        Primary Payment Gateway
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            label="Primary Payment Method"
                            name="primary_payment_method"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue("primary_payment_method", 1);
                              } else {
                                setFieldValue("primary_payment_method", 0);
                              }
                            }}
                            checked={
                              values.primary_payment_method === 1 ? 1 : 0
                            }
                            color="primary"
                          />
                        }
                        label={
                          values.primary_payment_method === 1
                            ? "Stripe"
                            : "BetterPay Authorize.Net"
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}></Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="BetterPay Authorize.Net API key"
                        name="api_key"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.api_key}
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.api_key && errors.api_key)}
                        helperText={touched.api_key && errors.api_key}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="BetterPay Authorize.Net API Secrate"
                        name="api_secret"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.api_secret}
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.api_secret && errors.api_secret)}
                        helperText={touched.api_secret && errors.api_secret}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Stripe API key"
                        name="stripe_api_key"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.stripe_api_key}
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.stripe_api_key && errors.stripe_api_key
                        )}
                        helperText={
                          touched.stripe_api_key && errors.stripe_api_key
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Stripe API Secrate"
                        name="stripe_api_secret"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.stripe_api_secret}
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.stripe_api_secret && errors.stripe_api_secret
                        )}
                        helperText={
                          touched.stripe_api_secret && errors.stripe_api_secret
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* <Grid item md={12} xs={12}>
              <Card>
                <CardHeader
                  subheader="Secondary Payment Gateway Setting"
                  title="Payment Gateway (Stripe)"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}></Grid>
                </CardContent>
              </Card>
            </Grid> */}
            <Grid item md={12} xs={12}>
              <Card>
                <CardHeader
                  subheader="The information can be edited"
                  title="Email Reminder"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prequestionnaire Reminder Duration"
                        name="prequestionnaire_remider_duration"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.prequestionnaire_remider_duration}
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.prequestionnaire_remider_duration &&
                            errors.prequestionnaire_remider_duration
                        )}
                        helperText={
                          touched.prequestionnaire_remider_duration &&
                          errors.prequestionnaire_remider_duration
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prequestionnaire Unit"
                        name="prequestionnaire_unit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.prequestionnaire_unit}
                        select
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.prequestionnaire_unit &&
                            errors.prequestionnaire_unit
                        )}
                        helperText={
                          touched.prequestionnaire_unit &&
                          errors.prequestionnaire_unit
                        }
                      >
                        {unit.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Postquestionnaire Reminder Duration"
                        name="postquestionnaire_remider_duration"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.postquestionnaire_remider_duration}
                        type="number"
                        required
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.postquestionnaire_remider_duration &&
                            errors.postquestionnaire_remider_duration
                        )}
                        helperText={
                          touched.postquestionnaire_remider_duration &&
                          errors.postquestionnaire_remider_duration
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Postquestionnaire Unit"
                        name="postquestionnaire_unit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.postquestionnaire_unit}
                        type="number"
                        required
                        select
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.postquestionnaire_unit &&
                            errors.postquestionnaire_unit
                        )}
                        helperText={
                          touched.postquestionnaire_unit &&
                          errors.postquestionnaire_unit
                        }
                      >
                        {unit.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  {chkRoutPerm ? (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default SettingsForm;
