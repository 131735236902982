import React, { useState } from "react";
import { Grid, Container, Paper, Box } from "@mui/material";
import { Formik } from "formik";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreQuestBox from "../../components/PrequestBox/index";
import BorderLinearProgress from "../../components/QuestionTemplates/BorderLinearProgress";
import CenterLoader from "../../components/PrequestBox/CenterLoader";
import { getSchema } from "../../utils/Validation";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function index({
  data,
  loading,
  progress,
  onSubmitForm,
  mode,
  isIframe,
  color,
}) {
  const theme = createTheme({
    palette: {
      custom: {
        main: "#03a9f4",
        contrastText: "#fff",
      },
    },
  });
  let InitialValues;
  if (
    data.question !== undefined &&
    data.question.name_fields !== undefined &&
    data.question.control === 14
  ) {
    InitialValues = {
      firstname:
        data.question.name_fields.first_name !== undefined
          ? data.question.name_fields.first_name
          : "",
      middlename:
        data.question.name_fields.middle_name !== undefined
          ? data.question.name_fields.middle_name
          : "",
      lastname:
        data.question.name_fields.last_name !== undefined
          ? data.question.name_fields.last_name
          : "",
    };
  }
  if (
    data.question !== undefined &&
    data.question.name_fields_female !== undefined &&
    data.question.control === 18
  ) {
    InitialValues = {
      firstname:
        data.question.name_fields_female.first_name !== undefined
          ? data.question.name_fields_female.first_name
          : "",
      middlename:
        data.question.name_fields_female.middle_name !== undefined
          ? data.question.name_fields_female.middle_name
          : "",
      lastname:
        data.question.name_fields_female.last_name !== undefined
          ? data.question.name_fields_female.last_name
          : "",
    };
  }
  if (
    data.question !== undefined &&
    data.question.name_fields !== undefined &&
    data.question.control === 13
  ) {
    let defaultValueDate =
      data.question.default_value !== ""
        ? data.question.default_value.split("-")
        : null;
    let defaultValueDate1 =
      defaultValueDate !== "" &&
      defaultValueDate !== undefined &&
      defaultValueDate !== null
        ? defaultValueDate[1] +
          "/" +
          defaultValueDate[2] +
          "/" +
          defaultValueDate[0]
        : "";
    InitialValues = {
      answer:
        data.question &&
        data.question.default_value !== "" &&
        defaultValueDate1 !== ""
          ? defaultValueDate1
          : "",
    };
  }
  if (data.question !== undefined && data.question.control === 10) {
    InitialValues = {
      answer: data.question ? data.question.default_value.toString() : "",
    };
  } else {
    InitialValues = {
      answer: data.question ? data.question.default_value : "",
    };
  }
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center">
              <Grid item md={12} xs={12}>
                <Paper
                  elevation={isIframe ? 0 : 3}
                  style={{ padding: 25, minHeight: 320 }}
                >
                  <Container>
                    <Grid
                      container
                      justifyContent="center"
                      alignContent="center"
                      style={{ height: 313 }}
                    >
                      <CenterLoader />
                    </Grid>
                  </Container>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} style={{ paddingTop: 20 }}>
                <Grid container justifyContent="space-between">
                  <Box
                    style={{
                      display: "flex",
                      color: "#fff",
                      alignItems: "center",
                    }}
                    disabled={true}
                  >
                    <FontAwesomeIcon
                      icon={faArrowCircleLeft}
                      color="#fff"
                      size="2x"
                      style={{ marginRight: 10 }}
                    />
                    Previous
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      color: "#fff",
                      alignItems: "center",
                    }}
                    disabled={true}
                  >
                    Next
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      color="#fff"
                      size="2x"
                      style={{ marginLeft: 10 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              direction="column"
            >
              <BorderLinearProgress variant="determinate" value={progress} />
              <p
                style={{
                  color: "#fff",
                  textAlign: "right",
                  justifyContent: "end",
                }}
              ></p>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="row" justifyContent="center">
        {/*isIframe && (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            direction="column"
            width={"70%"}
          >
            <BorderLinearProgress
              variant="determinate"
              value={progress}
              customcolor={color}
            />
          </Grid>
        )*/}
        {/* <Grid container justifyContent="center"> */}
        <Grid item xs={12} md={6}>
          <Formik
            // initialValues={{
            //   answer: data.question ? data.question.default_value : "",
            // }}
            initialValues={InitialValues}
            validationSchema={getSchema(data.question.control)}
            onSubmit={(values, actions) => {
              // console.log(values,'values--');
              if (data.question.control === 14) {
                let custValue = {
                  address: {
                    first_name: values.firstname,
                    middle_name: values.middlename,
                    last_name: values.lastname,
                  },
                  answer:
                    values.firstname +
                    "," +
                    values.middlename +
                    "," +
                    values.lastname,
                };
                onSubmitForm(custValue, actions, true);
              }if (data.question.control === 18) {
                let custValue = {
                  address: {
                    first_name: values.firstname,
                    middle_name: values.middlename,
                    last_name: values.lastname,
                  },
                  answer:
                    values.firstname +
                    "," +
                    values.middlename +
                    "," +
                    values.lastname,
                };
                onSubmitForm(custValue, actions, true);
              } else {
                //console.log(values,"Values...........");
                //return false;
                onSubmitForm(values, actions, true);
              }
              // onSubmitForm(values, actions, true);
            }}
          >
            {({ values, actions, handleSubmit, submitForm }) => {
              if (
                data.question.is_prequestadmin !== undefined &&
                data.question.is_prequestadmin === 1
              ) {
                return (
                  <Grid container justifyContent="center">
                    <Grid item md={12} xs={12}>
                      <Paper
                        elevation={isIframe ? 0 : 3}
                        style={{ padding: isIframe ? 0 : 25, minHeight: 320,color : '#526574', display:'flex', fontFamily: 'Poppins', fontSize:18, fontWeight:500,alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Container>
                          <center style={{display: "flex", flexDirection: 'column', alignItems: 'center'}}>
                            <p> Give yourself and your loved ones peace of mind by making prearrangements. Contact us at </p> 
                            <p><a href="mailto:info@fernwood.com"style={{ color: "#D8B34F" }}>info@fernwood.com</a><br/> or <br/><a href="tel:4157073393" style={{ color: "#D8B34F" }}>(415) 707-3393</a></p>
                            <p> We're here to assist you with care and compassion.</p>
                              <Box
                                style={{
                                  display: "flex",
                                  color : '#526574',
                                  alignItems: "center",
                                  cursor: "pointer",
                                  fontFamily: 'Poppins', 
                                  fontWeight:500,
                                  marginTop:10
                                }}
                                onClick={async () => {
                                  data.question.is_previous !== 1
                                    ? onSubmitForm(values, actions, false)
                                    : "";
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faArrowCircleLeft}
                                  color="#000"
                                  size="1.5x"
                                  style={{ marginRight: 10}}
                                />
                                Back
                              </Box>
                          </center>
                        </Container>
                      </Paper>
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container justifyContent="center">
                      <Grid item md={12} xs={12}>
                        <Paper
                          elevation={isIframe ? 0 : 3}
                          style={{ padding: 25, minHeight: 320 }}
                        >
                          <Container>
                            <PreQuestBox
                              question={data.question}
                              onSubmitForm={submitForm}
                              disabled={mode === "Edit"}
                              isIframe={isIframe}
                              values={values}

                            />
                          </Container>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={12} style={{ paddingTop: 20 }}>
                        <Grid container justifyContent="space-between">
                          <Box
                            style={{
                              display: "flex",
                              color: "#fff",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              data.question.is_previous !== 1
                                ? onSubmitForm(values, actions, false)
                                : "";
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faArrowCircleLeft}
                              color="#fff"
                              size="2x"
                              style={{ marginRight: 10 }}
                            />
                            Previous
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              color: "#fff",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              submitForm();
                            }}
                          >
                            Next
                            <FontAwesomeIcon
                              icon={faArrowCircleRight}
                              color="#fff"
                              size="2x"
                              style={{ marginLeft: 10 }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                );
              }
            }}
          </Formik>
        </Grid>
      </Grid>
      {data.question.is_prequestadmin !== undefined &&
      data.question.is_prequestadmin === 0 ? (
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              direction="column"
            >
              {/* <BorderLinearProgress variant="determinate" value={progress} customcolor={isIframe ? color : ''} /> */}
              <BorderLinearProgress variant="determinate" value={progress} customcolor='' />
              <p
                style={{
                  color:"#fff",
                  textAlign: "right !important",
                  justifyContent: "end",
                }}
              >
                {data.progressbar.index - 1} of {data.progressbar.total}{" "}
                Answered
              </p>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
}
