import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CategorySelection from "../CategorySelection/index";
import * as Http from "src/services/Http";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import ImportantNotice from "../ImportantNotice";
import { setPackageSelected } from "src/actions";
// import CustomStepper from "src/Components/Stepper";

export default function PackageBody({
  data,
  crtPrice,
  QuestionConditionChk,
  PreneedDoneChk,
  setTabval,
  setdisableTab,

  getExtraMailesCharges,
  extraMileServicePrice,
  setExtraMileServicePrice,
  ...otherProps
}) {
  const { name, price, description, addonCategory } = data;
  const navigate = useNavigate();
  const [isPaymentLoading, setisPaymentLoading] = useState(false);
  const [errorMessageForUrn, setErrorMessageForUrn] = useState("");
  const [showImportantNotice, setShowImportantNotice] = useState(false);
  const [
    errorMessageForAdditionServices,
    setErrorMessageForAdditionServices,
  ] = useState("");
  const [errorMessageForDelivery, setErrorMessageForDelivery] = useState("");
  const [
    errorMessageForDeliveryAddress,
    setErrorMessageForDeliveryAddress,
  ] = useState("");

  const [errorMessageForPeacemaker, setErrorMessageForPeacemaker] = useState(
    ""
  );
  const [errorMessageForServiceArea, setErrorMessageForServiceArea] = useState(
    ""
  );
  const [
    errorMessageForServiceAreaAddress,
    setErrorMessageForServiceAreaAddress,
  ] = useState("");

  const [refundPlus, setRefundPlus] = useState(otherProps.certificate);

  const dispatch = useDispatch();
  let disablePlusCertificate = false;
  let disableMiuxCertificate = false;

  const savePackages = async () => {
    setisPaymentLoading(true);
    let addOns = [];
    //let addOnsData = [];

    let isErrro = false;
    let selectedCategories = [];

    let deliveryAddress = "";
    let serviceAreaAddress = "";
    setErrorMessageForUrn("");
    setErrorMessageForAdditionServices("");
    setErrorMessageForDelivery("");
    setErrorMessageForDeliveryAddress("");

    setErrorMessageForPeacemaker("");
    setErrorMessageForServiceArea("");
    setErrorMessageForServiceAreaAddress("");
    otherProps.selected.map((item, index) => {
      if (!selectedCategories.includes(item.catId)) {
        selectedCategories.push(item.catId);
      }

      if (item.address !== undefined && item.address !== "") {
        deliveryAddress = item.address;
      }
      if (
        item.address !== undefined &&
        item.address !== "" &&
        item.catId === 32
      ) {
        serviceAreaAddress = item.address;
      }

      return addOns.push({
        id: item.id,
        quantity: item.quantity ? item.quantity : 0,
        state: item.state !== undefined ? item.state : "",
        address: item.address !== undefined ? item.address : "",

        city: item.city !== undefined ? item.city : "",
        country: item.country !== undefined ? item.country : "",
        lat: item.lat !== undefined ? item.lat : "",
        long: item.long !== undefined ? item.long : "",
        zip: item.zip !== undefined ? item.zip : "",
      });
    });

    // console.log(deliveryAddress, "deliveryAddress");

    const caterror = document.getElementById("caterror");
    if (!selectedCategories.includes(28) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForAdditionServices("Please select Additional Service.");
    } else if (!selectedCategories.includes(2) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDelivery("Please select your shipping option");
    } else if (
      selectedCategories.includes(2) &&
      deliveryAddress === "" &&
      caterror
    ) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForDeliveryAddress("Please enter delivery address.");
    } else if (!selectedCategories.includes(31) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForPeacemaker("Please select your peacemaker");
    } else if (!selectedCategories.includes(32) && caterror) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForServiceArea(
        "Please select your service area connection"
      );
    } else if (
      selectedCategories.includes(32) &&
      serviceAreaAddress === "" &&
      caterror
    ) {
      isErrro = true;
      caterror.scrollIntoView({ behavior: "smooth" });
      setErrorMessageForServiceAreaAddress("Please enter service area address");
    }

    if (!isErrro) {
      const package_id = JSON.parse(localStorage.getItem("packageData")).id;

      let local_discount = localStorage.getItem("discount");

      let discount_id = local_discount
        ? JSON.parse(local_discount).discount_id
        : "";

      let body = {
        package_id: package_id,
        addons_ids: addOns,
        // discount_id: discount_id,
        // user_id: JSON.parse(localStorage.getItem("state")).session.user.id,
        user_id: otherProps.user_id,
        no_of_cert: otherProps.certificate,
        is_edit:
          otherProps.refundStatus === true || otherProps.adjustPayment === true
            ? true
            : false,
        is_refund: otherProps.refundStatus === true ? true : false,
      };
      // console.log("body",body)
      await Http.call("POST", "add_subscription_details", body).then(
        (response) => {
          if (response.status === 200) {
            dispatch(setPackageSelected(true));
            if (parseInt(otherProps.is_Preneed) === 1) {
              setTabval("step6"); //gwquest show
              setdisableTab(["step2", "step4", "step5", "step7", "step12"]);
            } else {
              setShowImportantNotice(true);
            }
          } else {
            alert(response.message);
          }
        }
      );
    }
    setisPaymentLoading(false);
  };
  // console.log("data", data);

  return (
    <div>
      {showImportantNotice ? (
        <ImportantNotice
          setTabval={setTabval}
          setdisableTab={setdisableTab}
          is_Preneed={otherProps.is_Preneed}
        />
      ) : (
        <Grid
          className="scrollable-section"
          style={{
            backgroundImage: `url("/img/Rectangle24.png")`,
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            position: "relative",
            zIndex: "0",
          }}
        >
          <Grid className="container">
            {/* <div className="row">
          <div className="col-md-12">
            <CustomStepper currentStep={1} />
          </div>
        </div> */}
            <Grid className="row package-main package-custom-main">
              <Grid
                item
                //  className="col-lg-8 col-md-12 mb-4"
                xs={12}
                md={12}
                lg={8}
                style={{ margin: "0 auto" }}
              >
                <div className="selectServices selectServices-custom ">
                  <div className="first-content">
                    <h1>Select Services</h1>
                  </div>
                  <ul className="que-list ">
                    <li>
                      Our standard package includes a simple container to honor
                      your loved one. To create a truly personal tribute,
                      consider exploring our alternative range of beautiful
                      urns, pendants, votives, and other meaningful keepsakes
                      you and your loved ones can treasure.
                    </li>
                  </ul>
                </div>

                <div className="first-payment selectServices-custom">
                  <div className="payment-op1 ">
                    <h3>{`${name}`}</h3>
                    <h6
                    //  className="text-white"
                    >
                      ${`${parseFloat(price).toFixed(2)}`}
                    </h6>
                  </div>
                  <ul className="que-list ">
                    <li
                    //  className="text-white"
                    >
                      {/* <i className="fa fa-arrow-circle-o-right" aria-hidden="true">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i> */}
                      {`${description}`}
                    </li>
                  </ul>
                </div>

                {addonCategory.map((item, index) => {
                  if (item.addOns.length > 0) {
                    let selectedData = otherProps.selected.filter(
                      ({ catId }) => catId === item.id
                    );
                    return (
                      <CategorySelection
                        key={index}
                        data={item}
                        index={index}
                        selected={selectedData}
                        getShippingTotal={otherProps.getShippingTotal}
                        errorMessageForPeacemaker={errorMessageForPeacemaker}
                        setErrorMessageForPeacemaker={
                          setErrorMessageForPeacemaker
                        }
                        errorMessageForServiceArea={errorMessageForServiceArea}
                        setErrorMessageForServiceArea={
                          setErrorMessageForServiceArea
                        }
                        errorMessageForServiceAreaAddress={
                          errorMessageForServiceAreaAddress
                        }
                        setErrorMessageForServiceAreaAddress={
                          setErrorMessageForServiceAreaAddress
                        }
                        getExtraMailesCharges={getExtraMailesCharges}
                        extraMileServicePrice={extraMileServicePrice}
                        setExtraMileServicePrice={setExtraMileServicePrice}
                        errorMessageForUrn={errorMessageForUrn}
                        setErrorMessageForUrn={setErrorMessageForUrn}
                        errorMessageForAdditionServices={
                          errorMessageForAdditionServices
                        }
                        setErrorMessageForAdditionServices={
                          setErrorMessageForAdditionServices
                        }
                        errorMessageForDelivery={errorMessageForDelivery}
                        setErrorMessageForDelivery={setErrorMessageForDelivery}
                        errorMessageForDeliveryAddress={
                          errorMessageForDeliveryAddress
                        }
                        setErrorMessageForDeliveryAddress={
                          setErrorMessageForDeliveryAddress
                        }
                        refundStatus={otherProps.refundStatus}
                        adjustPayment={otherProps.adjustPayment}
                      />
                    );
                  }
                })}
                <div className="bill-container-custom">
                  <div className="payment-op1 bill-container bill-content-custom">
                    <p>Select no. of death certificates</p>
                    <div className="que-option  que-option-for-mobile">
                      <div className="number-input">
                        <button
                          onClick={() => {
                            if (
                              otherProps.certificate <= refundPlus &&
                              otherProps.adjustPayment === true
                            ) {
                              disableMiuxCertificate = true;
                              return;
                            }
                            if (otherProps.certificate > 1) {
                              otherProps.setCertificate(
                                otherProps.certificate - 1
                              );
                            }
                          }}
                          className="minus"
                          disabled={disableMiuxCertificate}
                        >
                          -
                        </button>
                        <input
                          className="quantity"
                          min="0"
                          name="quantity"
                          value={otherProps.certificate}
                          type="number"
                          // onChange={(e) => otherProps.setCertificate(e.target.value)}
                          readOnly={true}
                        />
                        {/* <button
                          onClick={() =>
                            otherProps.setCertificate(
                              parseInt(otherProps.certificate) + 1
                            )
                          }
                          className="plus"
                        >
                          +
                        </button> */}
                        <button
                          onClick={() => {
                            // console.log("refundPlus",refundPlus)
                            // console.log("additional certificate==>",otherProps.setCertificate)

                            if (
                              refundPlus <= otherProps.certificate &&
                              otherProps.refundStatus === true
                            ) {
                              disablePlusCertificate = true;
                              return;
                            }

                            otherProps.setCertificate(
                              parseInt(otherProps.certificate) + 1
                            );
                          }}
                          className="plus"
                          disabled={disablePlusCertificate}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="certificate-amount">
                      <h6
                      //  className="text-white"
                      >
                        $
                        {parseFloat(
                          otherProps.certificate * crtPrice - crtPrice
                        ).toFixed(2)}
                      </h6>
                    </div>
                  </div>
                  <div className="payment-op1 bill-container bill-content-custom bill-content2">
                    <p>Tax Calculation</p>
                    <h6
                    // className="text-white"
                    >
                      ${`${parseFloat(otherProps.getTotalTax()).toFixed(2)}`}
                    </h6>
                  </div>
                  <div className="payment-op1 bill-container bill-content-custom bill-content3">
                    <p>Total amount due</p>
                    <h6
                      //  className="text-white"
                      className="total-Price"
                    >
                      <b>${`${parseFloat(otherProps.total()).toFixed(2)}`}</b>
                      {/* ${`${parseFloat(otherProps.total).toFixed(2)}`} */}
                    </h6>
                  </div>

                  <div
                    className="cat-error error-background-color"
                    id="caterror"
                  >
                    {errorMessageForUrn !== "" && <h3>{errorMessageForUrn}</h3>}
                    {errorMessageForAdditionServices !== "" && (
                      <h3>{errorMessageForAdditionServices}</h3>
                    )}
                    {errorMessageForDelivery !== "" && (
                      <h3>{errorMessageForDelivery}</h3>
                    )}
                    {errorMessageForDeliveryAddress !== "" && (
                      <h3>{errorMessageForDeliveryAddress}</h3>
                    )}
                  </div>
                  <div className="payment-op1">
                    <button
                      type="text"
                      className="btn-apply btn-continue mt-4 mx-0 mb-4 bill-container-button-custom"
                      // onClick={() => savePackages()}

                      onClick={() =>
                      {
                          if(otherProps.refundStatus || otherProps.adjustPayment){
                            savePackages()
                              setTabval("step12")
                            console.log("called refund")
                          }else{
                            savePackages()
                            console.log("no flag work")
                            }
                        }
                      }
                      
                    >
                      Continue
                    </button>
                  </div>
                </div>

                {/* <div className="amount-note">
              <p>There are no additional costs or hidden charges.</p>
            </div> */}
              </Grid>

              <Grid
                style={{ margin: "32px auto 0px auto" }}
                item
                //  className="col-lg-4 col-md-12 pb-60"
                xs={12}
                md={12}
                lg={8}
              >
                {/* ----------------------------------------old-------------------------------------------- */}
                {/* <div className="quote quote-custom ">
                  <h6>Summary</h6>

                  <div className="servicre-package">
                    <h3>{`${name}`}</h3>
                    <span>
                      {" "}
                      <b>${`${parseFloat(price).toFixed(2)}`}</b>
                    </span>
                  </div>
                  <>
                    {addonCategory.map((category, index) => {
                      if (category.id != 2) {
                        if (category.addOns.length > 0) {
                          let selectedServices = otherProps.selected.filter(
                            ({ catId }) => catId === category.id
                          );

                          if (selectedServices.length > 0) {
                            return (
                              <div key={index} className="service-category">
                                <p>{category.name}</p>

                                {selectedServices.map(
                                  (selectedService, idx) => (
                                    <div key={idx} className="quote-service">
                                      <span className="service-name">
                                        {selectedService.name}
                                        {category.id !== 28 &&
                                          `(${selectedService?.quantity})`}
                                      </span>
                                      <span>
                                        {category.id !== 28 ? (
                                          <b>
                                            {" "}
                                            {category.id === 32 &&
                                            selectedService.id === 77
                                              ? "$" +
                                                parseFloat(
                                                  extraMileServicePrice
                                                ).toFixed(2)
                                              : "$" +
                                                parseFloat(
                                                  selectedService.price *
                                                    selectedService.quantity
                                                ).toFixed(2)}
                                          </b>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            );
                          }
                        }
                      }
                      return null;
                    })}
                    {otherProps.getShippingTotal() !== undefined &&
                    otherProps.getShippingTotal() > 0 ? (
                      <div className="quote-service-part dashed-line">
                        <span>Shipping </span>
                        <span>
                          <b>
                            $
                            {parseFloat(otherProps.getShippingTotal()).toFixed(
                              2
                            )}
                          </b>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="service-payment-main">
                      <div className="quote-service-part">
                        <span>
                          Death certificates ({otherProps.certificate})
                        </span>
                        <span>
                          <b>
                            $
                            {parseFloat(
                              otherProps.certificate * crtPrice - crtPrice
                            ).toFixed(2)}
                          </b>
                        </span>
                      </div>
                      <div className="quote-service">
                        <span style={{ color: "black" }}>Tax Calculation</span>
                        <span>
                          <b>
                            $
                            {`${parseFloat(otherProps.getTotalTax()).toFixed(
                              2
                            )}`}
                          </b>
                        </span>
                      </div>
                    </div>

                    <div className="quote-service quote-service-custom ">
                      <p>Your Quote</p>
                      <h4 className="total-Price">
                        <b>${`${parseFloat(otherProps.total()).toFixed(2)}`}</b>
                      </h4>
                    </div>
                  </>
                  <button
                    className="btn-apply-summary quote-button-custom"
                    onClick={() => savePackages()}
                  >
                    CONTINUE &nbsp;
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </div> */}
                {/* ----------------------------------------NEW-------------------------------------------- */}
                <div className="quote quote-custom ">
                  <h6 className="text-white">Summary</h6>

                  <div className="servicre-package">
                    <h3>{`${name}`}</h3>
                    <span>
                      {" "}
                      <b>${`${parseFloat(price).toFixed(2)}`}</b>
                    </span>
                  </div>
                  <>
                    {addonCategory.map((category, index) => {
                      // if (category.id != 28 && category.id != 2) {
                      if (category.id != 2) {
                        if (category.addOns.length > 0) {
                          let selectedServices = otherProps.selected.filter(
                            ({ catId }) => catId === category.id
                          );

                          if (selectedServices.length > 0) {
                            // Filter out services that should not be displayed
                            const filteredServices = selectedServices.filter(
                              (selectedService) => {
                                return !(
                                  category.id === 31 &&
                                  selectedService.name !== "Yes"
                                );
                              }
                            );

                            if (filteredServices.length > 0) {
                              return (
                                <div key={index} className="service-category">
                                  {(category.id === 32 ||
                                    (category.id === 31 &&
                                      selectedServices.some(
                                        (service) => service.name === "Yes"
                                      ))) && <p>{category.name}</p>}

                                  {filteredServices.map(
                                    (selectedService, idx) => (
                                      <div key={idx} className="quote-service">
                                        <span className="service-name">
                                          {category.id === 31 &&
                                          selectedService.name === "Yes" ? (
                                            `Implant Disposition (${selectedService.quantity})`
                                          ) : category.id === 32 ? (
                                            //  && selectedService.name === "No"
                                            <>
                                              {selectedService.quantity === 1
                                                ? "0"
                                                : // : selectedService.quantity <= 20
                                                  // ? "0"
                                                  selectedService.quantity}{" "}
                                              miles,
                                              <br />
                                              {selectedService.address}
                                            </>
                                          ) : (
                                            selectedService.name
                                          )}
                                          {category.id !== 28 &&
                                            category.id !== 31 &&
                                            category.id !== 32 &&
                                            `(${selectedService?.quantity})`}
                                        </span>
                                        <span>
                                          {category.id !== 28 ? (
                                            <b>
                                              {" "}
                                              {category.id === 32 &&
                                              selectedService.id === 77
                                                ? "$" +
                                                  parseFloat(
                                                    selectedService.quantity <=
                                                      20
                                                      ? "0"
                                                      : extraMileServicePrice
                                                  ).toFixed(2)
                                                : "$" +
                                                  parseFloat(
                                                    selectedService.price *
                                                      selectedService.quantity
                                                  ).toFixed(2)}
                                            </b>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              );
                            }
                          }
                          return null; // Return null if no services to display
                        }
                      }
                      return null;
                    })}

                    {/* <-----------shipping-------- */}
                    {addonCategory.map((category, index) => {
                      if (category.id === 2) {
                        console.log(category);
                        console.log("category", category);

                        if (category.addOns.length > 0) {
                          let selectedServices = otherProps.selected.filter(
                            ({ catId }) => catId === category.id
                          );

                          if (selectedServices.length > 0) {
                            return (
                              <div key={index} className="service-category">
                                <p>{category.name}</p>

                                {selectedServices.map(
                                  (selectedService, idx) => (
                                    <div key={idx} className="quote-service">
                                      <span className="service-name">
                                        {selectedService.name} <br />
                                        {selectedService.address}
                                      </span>
                                      <span>
                                        <b>
                                          $
                                          {parseFloat(
                                            otherProps.getShippingTotal()
                                          ).toFixed(2)}
                                        </b>
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            );
                          }
                        }
                      }
                      return null;
                    })}

                    <div className="service-payment-main">
                      <div className="quote-service-part">
                        <span>
                          Death certificates ({otherProps.certificate})
                        </span>
                        <span>
                          <b>
                            $
                            {parseFloat(
                              otherProps.certificate * crtPrice - crtPrice
                            ).toFixed(2)}
                          </b>
                        </span>
                      </div>
                      <div className="quote-service">
                        <span style={{ color: "black" }}>Tax Calculation</span>
                        <span>
                          <b>
                            $
                            {`${parseFloat(otherProps.getTotalTax()).toFixed(
                              2
                            )}`}
                          </b>
                        </span>
                      </div>
                    </div>

                    <div className="quote-service quote-service-custom ">
                      <p>Your Quote</p>
                      <h4 className="total-Price">
                        <b>${`${parseFloat(otherProps.total()).toFixed(2)}`}</b>
                      </h4>
                    </div>
                  </>

                  {/* <p className="text-white">
                  ${`${parseFloat(otherProps.total).toFixed(2)}`}
                </p> */}
                  <button
                    className="btn-apply-summary quote-button-custom"
                    onClick={() =>
                    {
                      if(otherProps.refundStatus || otherProps.adjustPayment){
                          savePackages()
                          setTabval("step12")
                        console.log("called refund")
                      }else{
                        savePackages()
                        console.log("no flag work")
                        }
                    }
                    }
                  >
                    CONTINUE &nbsp;
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
