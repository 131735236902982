import * as appConst from "src/constants";
export const call = async (method, callUrl, body, isMedia = false, isMultiMedia = false) => {
  let token = await localStorage.getItem("token");
  let response = null;
  let api_url = appConst.API_URL + callUrl;

  let bodyValue = null;
  let Header = null;
  if (isMedia) {
    Header = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    bodyValue = body;
  } else if(isMultiMedia) { 
    Header = {
      Accept: "multipart/form-data",
      Authorization: "Bearer " + token,
    };

    bodyValue = body;
  } else {
    Header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    bodyValue = JSON.stringify(body);
  }

  await fetch(api_url, {
    method: method,
    headers: Header,
    body: bodyValue,
    timeout: 6000
  })
    .then((response) => response.json())
    .then((responseJson) => {
      //console.log(responseJson);
      response = responseJson;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      //setLoading(false);
      //store.dispatch(setSnackbar(true));
    });
  return response;
};
export const Login = async (method, callUrl, body) => {
  let response = null;
  let api_url = appConst.API_URL + callUrl;
  await fetch(api_url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      response = responseJson;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      //setLoading(false);
      //store.dispatch(setSnackbar(true));
    });
  // console.log(response);

  return response;
};
