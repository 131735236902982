import React, { useState, useEffect } from "react";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import TabPanel from "src/components/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";

const breadUrlList = [
  { name: "Home", url: "/counselors" },
  { name: "Application List", url: "/" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CounsellorListView = (props) => {
  const classes = useStyles();

  const [counsellorList, setCounsellorList] = useState({});
  const [Loading, setLoading] = useState(true);
  const [counsellorsData, setCounsellorsData] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [value, setValue] = useState("in_progress");

  const CounsellorList = async (flag) => {
    try {
      await Http.call(
        "POST",
        "admin/counselors/application/listing",
        { flag: flag }
      ).then( async (response) => {
        setCounsellorList(response.data);
        await CounsellorsData();
        setLoading(false);
      });
      // setTimeout(() => {
      // }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  const CounsellorsData = async () => {
    try {
      await Http.call("GET", "admin/counselors")
      .then( async (response) => {
        if (response.status === 200) {
          setCounsellorsData(response.data);
          // props.setSnackbar(true, response.status, response.message);
        }  
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const counsellorUpdate = async (data) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/counselors/application", data).then(
        async (response) => {
          if (response.status === 200) {
            await CounsellorList(value);
            props.setSnackbar(true, response.status, response.message);
            setSelectedIds([]);
          } else {
            props.setSnackbar(true, response.status, response.message);
          }
        }
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const counsellorUnassign = async (data) => {
    setLoading(true);
    try {
      await Http.call(
        "POST",
        "admin/counselors/application/unassign",
        data
      ).then(async (response) => {
        if (response.status === 200) {
          await CounsellorList(value);
          props.setSnackbar(true, response.status, response.message);
        } else {
          props.setSnackbar(true, response.status, response.message);
        }
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    CounsellorList(newValue);
    setLoading(true);
  };

  useEffect(() => {
    CounsellorList("in_progress");
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.page} title="Application">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar
          selectedIds={selectedIds}
          counsellorsDataArr={counsellorsData}
          fetchCounsellorsData={CounsellorsData}
          counsellorUpdate={counsellorUpdate}
          userData={props.userData}
        />
        <Paper className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="in_progress"
              label="In Progress"
              {...a11yProps("in_progress")}
            />
            <Tab
              value="unassigned"
              label="UNASSIGNED"
              {...a11yProps("unassigned")}
            />
            <Tab value="assigned" label="ASSIGNED" {...a11yProps("assigned")} />
            <Tab
              value="complete"
              label="COMPLETED"
              {...a11yProps("complete")}
            />
            <Tab value="archived" label="ARCHIVED" {...a11yProps("archived")} />
          </Tabs>
        </Paper>
        <Box>
          <Results
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            counsellors={counsellorList}
            Counsellor={CounsellorList}
            pageLoading={setLoading}
            // Loading={Loading}
            counsellorsDataArr={counsellorsData}
            fetchCounsellorsData={CounsellorsData}
            counsellorUpdate={counsellorUpdate}
            counsellorUnassign={counsellorUnassign}
            userData={props.userData}
            currntTabsValue={value}
          />
        </Box>
      </Container>
    </Page>
  );
};

//export default CounsellorListView;
const mapStateToProps = (state) => ({
  userData: state.session.user,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CounsellorListView);
