import React from "react";
import { Button, Typography, Container, Grid } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom"; // Import useHistory for redirection
// import CustomStepper from "src/Components/Stepper";

const ImportantNotice = ({ setdisableTab, setTabval, is_Preneed }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    // Redirect to another page
    // navigate("/app/payment");
    // if (parseInt(is_Preneed) === 1) {
    //   setTabval("step6");
    //   setdisableTab(["step2", "step4", "step5", "step7", "step12"]);
    // } else {
      setTabval("step4");
      setdisableTab(["step2", "step5", "step6", "step7", "step12"]);
    // }
  };

  return (
    <div
      className="scrollable-section"
      style={{
        backgroundImage: `url("/img/Rectangle24.png")`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        position: "relative",
        height: "100vh",
        zIndex: "0",
      }}
    >
      <Grid container style={{width:"80%",margin:"0 auto"}} >
        {/* <div className="row">
          <div className="col-md-12">
            <CustomStepper currentStep={1} />
          </div> 
        </div>*/}
        <div className="row package-main important-notes">
          <Grid item xs={10} md={8} lg={12} sx={{ mb: 2 }}>
            {" "}
            {/* Adjust container width as needed */}
            <Typography
              variant="h4"
              gutterBottom
              style={{
                marginBottom: "20px",
                fontSize: "32px",
                color: "#169873",
              }}
            >
              Important Notice Regarding Payment Methods:
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                listStyle: "none",
                display: "inline-flex",
                margin: "0",
                padding: "0px 0",
                color: "#283a6a",
                fontSize: "18px",
                wordBreak: "normal",
              }}
            >
              As we proceed with the arrangements for your loved one's services,
              we want to ensure a smooth and respectful process for all
              involved. It's crucial to note that any attempt to use a credit
              card belonging to a person who has passed will result in the
              application being declined. This measure is in place to comply
              with financial regulations and to prevent unauthorized use of the
              deceased's assets.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                listStyle: "none",
                display: "inline-flex",
                margin: "0",
                padding: "0px 0",
                color: "#283a6a",
                fontSize: "18px",
                wordBreak: "normal",
              }}
            >
              We kindly ask that you arrange for an alternative payment method
              for the services. Our team is here to guide you through the
              available options and assist in any way we can during this
              difficult time. If you have any questions or need further
              assistance, please do not hesitate to reach out to us.
            </Typography>
            <Button
              variant="contained"
              // color="primary"
              className="btn-apply btn-continue-important mt-2 mx-0 mb-2"
              style={{ marginTop: "20px" }}
              onClick={handleRedirect}
            >
              I CONFIRM
            </Button>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default ImportantNotice;
