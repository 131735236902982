import * as actionTypes from "../actions";
const initialState = {
  certificatePrice: 0,
  categoryData: [],
  ispackageSelected: false,
};

const packageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CRT_PRICE:
      return {
        ...state, 
        certificatePrice: payload.price,
      };
    case actionTypes.SET_PACKAGE_DATA:
      let existing = state.categoryData;

      if (payload.value.type) {
        let foundIndex = existing.findIndex((el) => el.id === payload.value.id);
        if (foundIndex !== -1) {
          if (!payload.value.selected) {
            existing.splice(foundIndex, 1);
          } else {
            existing[foundIndex] = payload.value;
          }
        } else {
          existing.push(payload.value);
        }
      } else {
        let foundIndex = existing.findIndex(
          (el) => el.catId === payload.value.catId
        );
        if (existing[foundIndex]) {
          existing[foundIndex] = payload.value;
        } else {
          existing.push(payload.value);
        }
      }
      //console.log(existing[foundIndex]);
      return {
        ...state,
        categoryData: existing,
      };
    case actionTypes.EMPTY_PACKAGE_DATA:
      return {
        ...state,
        categoryData: [],
      };
    case actionTypes.SET_PACKAGE_SELECT:
      return {
        ...state,
        ispackageSelected: payload.isPackageSelected,
      };
    default:
      return state;
  }
};
export default packageReducer;
