import React, { useState } from "react";
import { connect } from "react-redux";
import { setPackageData } from "src/actions";
import placeholder from "src/assets/img/image_placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Close } from "@material-ui/icons";
import { Box, IconButton, Modal } from "@material-ui/core";

export const RadioOption = ({
  data,
  categoryKey,
  addOnsKey,
  selected,
  type,
  setDeliveryAddon,
  shipping_inside,
  shipping_outside,
  ...props
}) => {
  let isSelected = false;
  let selectedString = type ? "checkbox" : "radio";
  const {
    id,
    service_name,
    price,
    tax,
    tax_per,
    image,
    thumb_image,
    is_included,
    is_selected,
    quantity: initialQuantity,
    // quantity,
  } = data;
  if (selected) {
    if (is_selected) {
      isSelected = is_selected;
      selectedString = type ? "checkbox-checked" : "radio-checked";
    } else if (selected.id === id) {
      isSelected = true;
      selectedString = type ? "checkbox-checked" : "radio-checked";
    }
  }
  let isDisabledMainCard=false;
  let isDisabledPlus=false;
  let isDisabledMinux=false;
  const [open, setOpen] = useState(false);
  const [Mainimage, setImage] = useState("false");
  // const [refundQuantity, setRefundQuantity] = useState(props.categoryData.quantity)
  const [refundQuantity, setRefundQuantity] = useState(selected?.quantity || initialQuantity)
  const [selectedItemID, setSelectedItemID] = useState(selected?.id)


  // console.log("refundStatus-om-radio-page", props.refundStatus);
  // console.log("refundStatusdata", refundQuantity);
  // console.log("selectedItemID -->", selectedItemID);

  // console.log("refundStatus-selected", selected?.quantity);


  // console.log("adjustPayment-payment button==>",props.adjustPayment)

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // const isDisabled = () => {
  //   console.log("first");
  //   const addon = props.categoryData.find((item) => item.id === id);
  //   const maxQuantity = addon ? addon.maxQuantity : 10; // Ensure maxQuantity is properly assigned
  //   if (data.quantity <= addon?.quantity) {
  //     console.log("Quantity exceeded maximum not allowed.");
  //     return true; // Disable if quantity exceeds maxQuantity
  //   } else {
  //     console.log("Quantity exceeded maximum allowed.");
  //     return false; // Enable if quantity is within maxQuantity
  //   }
  // };
  return (
    <>
      <div className={`plans ${isSelected ? "activeplans" : ""}`}>
        <label className="plan basic-plan" htmlFor={"flexRadioDefault" + id}>
          <input
            type={type ? "checkbox" : "radio"}
            // className="addon_box"
            name={"addons_ids[" + id + "]"}
            id={"flexRadioDefault" + id}
            checked={isSelected}
            value={id}
            onChange={(e) => {
              let addon = props.categoryData.find((item) => item.id === id);
              let categoryWiseSelectedAddonsCount = 0;
              let categoryWiseSelectedAddons = props.categoryData.filter(
                (item) => item.catId === categoryKey
              );
              
              if(props.refundStatus===true ){
                if( refundQuantity === 0){
                  isDisabledMainCard=true;
                  return;
                }
              }
              
              if (categoryKey === 1) {
                props.setErrorMessageForUrn("");
              }
              if (categoryKey === 28) {
                props.setErrorMessageForAdditionServices("");
              }
              if (categoryKey === 2) {
                props.setErrorMessageForDelivery("");
                props.setErrorMessageForDeliveryAddress("");
              }
              if (categoryKey === 31) {
                props.setErrorMessageForPeacemaker("");
              }
              if (categoryKey === 32) {
                props.setErrorMessageForServiceArea("");
                props.setErrorMessageForServiceAreaAddress("");
                props.setExtraMileServicePrice(0);
              }
              if (categoryWiseSelectedAddons !== undefined) {
                if (categoryWiseSelectedAddons.length > 0) {
                  categoryWiseSelectedAddons.map((catSelItem) => {
                    categoryWiseSelectedAddonsCount += catSelItem.quantity;
                  });
                }
              }
              if (isSelected) {
                addon = {
                  ...addon,
                  selected: false,
                  quantity: 0,
                };

                props.setPackageData(addon);
              } else {
                if (type) {
                  if (categoryWiseSelectedAddonsCount < 10) {
                    props.setPackageData({
                      id: id,
                      catId: categoryKey,
                      name: service_name,
                      price: price,
                      tax: tax,
                      tax_per: tax_per,
                      type: type,
                      selected: true,
                      included: is_included ? true : false,
                      quantity: 1,
                      address: "",
                      shipping_inside: shipping_inside,
                      shipping_outside: shipping_outside,
                    });
                  }
                } else {
                  props.setPackageData({
                    id: id,
                    catId: categoryKey,
                    name: service_name,
                    price: price,
                    tax: tax,
                    tax_per: tax_per,
                    type: type,
                    selected: true,
                    quantity: 1,
                    // address: id === 9 ? props.collectPerson : "",
                    address:
                      id === 9
                        ? props.collectPerson
                        : id === 76
                        ? props.collectServicePerson
                        : "",

                    shipping_inside: shipping_inside,
                    shipping_outside: shipping_outside,
                  });
                }
              }
            }}
            // disabled={!selected || props.refundStatus ? true : false}
            disabled={isDisabledMainCard}
          />
          <div className="plan-content">
            <div className="plan-img">
              {image !== "" ? (
                <img
                  loading="lazy"
                  className="mx-3 lgt-img text-white"
                  src={`${image}`}
                  alt=""
                  onClick={(e) => handleImage(image)}
                />
              ) : (
                <img
                  loading="lazy"
                  className="lgt-img text-white"
                  src={placeholder}
                  alt=""
                  onClick={(e) => handleImage(placeholder)}
                />
              )}
            </div>

            <div className="plan-details">
              <span
                //  className="text-white"
                className="service-name"
              >{`${service_name}`}</span>

              {type ? (
                is_included ? (
                  <p className="service-price">
                    ${parseFloat(price).toFixed(2)} (1 Included)
                  </p>
                ) : (
                  <p className="service-price ">
                    +${parseFloat(price).toFixed(2)}
                  </p>
                )
              ) : (
                ""
              )}
              {type ? (
                <div className="number-input category-pkg-qty">
                  <button
                    // onClick={() => props.setPackageQty(id, parseInt(props.pkgqty) + 1)}
                    onClick={() => {
                      let addon = props.categoryData.find(
                        (item) => item.id === id
                      );
                   
                      let categoryWiseSelectedAddonsCount = 0;
                      let categoryWiseSelectedAddons = props.categoryData.filter(
                        (item) => item.catId === categoryKey
                      );

                      // console.log("refundQuantity",refundQuantity)
                      if(refundQuantity <= addon?.quantity && props.refundStatus===true || (refundQuantity === 0 && props.refundStatus===true)){
                        // console.log("addon?.quantity",addon?.quantity)
                        isDisabledPlus=true
                        return;
                      }


                      if (categoryWiseSelectedAddons !== undefined) {
                        if (categoryWiseSelectedAddons.length > 0) {
                          categoryWiseSelectedAddons.map((catSelItem) => {
                            categoryWiseSelectedAddonsCount +=
                              catSelItem.quantity;
                          });
                        }
                      }

                      if (addon !== undefined) {
                        if (addon.quantity === undefined) {
                          addon = {
                            ...addon,
                            selected: true,
                            quantity: 1,
                          };
                        } else {
                          if (addon.quantity < 10) {
                            addon = {
                              ...addon,
                              quantity: addon.quantity + 1,
                            };
                          }
                        }
                        if (categoryWiseSelectedAddonsCount < 10) {
                          props.setPackageData(addon);
                        }
                      } else {
                        if (categoryWiseSelectedAddonsCount < 10) {
                          props.setPackageData({
                            id: id,
                            catId: categoryKey,
                            name: service_name,
                            price: price,
                            tax: tax,
                            tax_per: tax_per,
                            type: type,
                            selected: true,
                            included: is_included ? true : false,
                            quantity: 1,
                            // address: id === 9 ? props.collectPerson : "", //new conditon apply when address issue
                            address:
                              id === 9
                                ? props.collectPerson
                                : id === 76
                                ? props.collectServicePerson
                                : "",
                            shipping_inside: shipping_inside,
                            shipping_outside: shipping_outside,
                          });
                        }
                      }
                    }}
                    className="plus"
                    disabled = {isDisabledPlus}
                    // disabled={props.refundStatus ? isDisabled() : false}
                  >
                    +
                  </button>
                  <input
                    className="quantity"
                    min="0"
                    name="quantity"
                    value={
                      selected !== undefined
                        ? selected.quantity
                          ? selected.quantity
                          : initialQuantity 
                        : 0
                    }
                    type="number"
                    max="10"
                    readOnly={true}
                  />
                  <button
                    onClick={() => {
                      let addon = props.categoryData.find(
                        (item) => item.id === id
                      );
                      // console.log("check minux",addon?.quantity);
                      // isDisabledMinux = (addon?.quantity === 1 ) && props.refundStatus ? true : false
                      // isDisabledMinux = selected?.quantity === 1  ? true : false;
                      // if(selected?.quantity === 1 && props.refundStatus){
                      //   console.log("first minux")
                      //   isDisabledMinux=true
                      //   return;
                      // }
                      // console.log(isDisabledMinux,'minux');
                      
                      
                      // console.log("refundQuantity",refundQuantity)
                      if(refundQuantity >= addon?.quantity && props.adjustPayment===true || !selected){
                        // console.log("secound minux")
                        isDisabledMinux=true
                        return;
                      }


                      
                      if (
                        selected !== undefined &&
                        selected.quantity !== undefined &&
                        selected.quantity >= 1 &&
                        selected.quantity <= 10
                      ) {
                        if (addon !== undefined) {
                          if (addon.quantity !== undefined) {
                            if (selected.quantity === 1) {
                              addon = {
                                ...addon,
                                selected: false,
                                quantity: addon.quantity - 1,
                              };
                            } else {
                              addon = {
                                ...addon,
                                quantity: addon.quantity - 1,
                              };
                            }
                          }
                          props.setPackageData(addon);
                        }
                      }
                    }}
                    className="minus"
                    disabled ={isDisabledMinux}
                  >
                    -
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            {isSelected ? (
              <div className="planselectedicon">
                <i className="fa fa-solid fa-check" aria-hidden="true">
                  <FontAwesomeIcon icon={faCheck} />
                </i>
              </div>
            ) : (
              ""
            )}
          </div>
        </label>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <img
            src={Mainimage}
            alt="asd"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryData: state.package.categoryData,
});

const mapDispatchToProps = {
  setPackageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RadioOption);
