import React from "react";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Button, makeStyles } from "@material-ui/core";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import MUIDataTable from "mui-datatables";
import ReviewDialogs from "./reviewdialog.js";
import { Edit, Eye } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import { chkRoutePerm } from "src/services/Helper";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(3),
  },
  remark: {
    cursor: "pointer",
  },
}));

const TrimMyString = (string, maxLength, start = 0) => {
  if (string.length > maxLength) {
    let trimmedString = string.substr(start, maxLength);
    return (
      trimmedString.substr(
        start,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
      ) + " ..."
    );
  }
  return string;
};

const Results = ({
  className,
  reviews,
  pageLoading,
  fetchReview,
  setStatus,
  deleteReview,
  ...rest
}) => {
  const classes = useStyles();
  const responsive = "standard";
  const chkEditRoutPerm = chkRoutePerm("/app/reviews/edit", rest.authuser);
  const chkDelRoutPerm = chkRoutePerm("/app/reviews/delete", rest.authuser);

  const options = {
    filter: false,
    filterType: "dropdown",
    responsive,
    selectableRowsHeader: true,
    // selectableRowsHideCheckboxes: true,
    rowsPerPageOptions: [],
    rowsPerPage: 100,
    tableBodyMaxHeight: "740px",
    // rowsPerPageOptions: [10, 25, 50, 100, 500],
    downloadOptions: { filename: "Reviews.csv" },
    onRowsDelete: async (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map((d) => reviews[d.dataIndex].id);
      const titlesToDelete = rowsDeleted.data.map(
        (d) => reviews[d.dataIndex].title
      );
      deleteReview(idsToDelete, titlesToDelete);
    },
    isRowSelectable: () => {
      return chkDelRoutPerm ? true : false;
    },
  };

  const columns = [
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        display: chkEditRoutPerm ? true : false,
        customBodyRenderLite: (dataIndex) => (
          <div className="ta-flex actionIcon">
            <Button
              color="primary"
              size="small"
              // variant="outlined"
              disabled={reviews[dataIndex].status !== 0}
              onClick={() => {
                setStatus(reviews[dataIndex].id, 1);
              }}
              // className={classes.margin}
            >
              <CheckSharpIcon />
            </Button>
            <Button
              color="primary"
              size="small"
              // variant="outlined"
              disabled={reviews[dataIndex].status !== 0}
              onClick={() => {
                setStatus(reviews[dataIndex].id, 2);
              }}
            >
              <CloseSharpIcon />
            </Button>
            <Button
              color="primary"
              size="small"
              // variant="outlined"
              component={RouterLink}
              disabled={reviews[dataIndex].status !== 0}
              to={"/app/review/" + reviews[dataIndex].id}
            >
              <Edit />
            </Button>
          </div>
        ),
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "star",
      label: "Rating",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          reviews[dataIndex].star === 1
            ? "Poor"
            : reviews[dataIndex].star === 2
            ? "Needs Improvement"
            : reviews[dataIndex].star === 3
            ? "Neutral"
            : reviews[dataIndex].star === 4
            ? "Very Good"
            : reviews[dataIndex].star === 5
            ? "Excellent"
            : "—",
      },
    },
    // {
    //   name: "star",
    //   label: "Rating",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRenderLite: (dataIndex) => (
    //       <Rating
    //         name="read-only"
    //         value={reviews[dataIndex].star}
    //         precision={0.5}
    //         readOnly
    //       />
    //     ),
    //   },
    // },
    {
      name: "remark",
      label: "Review",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) => (
          <div className={classes.remark}>
             <ReviewDialogs
              // text={TrimMyString(reviews[dataIndex].remark, 55)}
              text={<Eye />}
            >
              {reviews[dataIndex].remark}
            </ReviewDialogs>
          </div>
        ),
      },
    },
    {
      name: "is_allow_public_shared",
      label: "My review publicliy shared",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          reviews[dataIndex].is_allow_public_shared === 1
            ? "Yes"
            : "No",
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          reviews[dataIndex].status === 1
            ? "Published"
            : reviews[dataIndex].status === 2
            ? "Reject"
            : "Pending",
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex) =>
          moment(reviews[dataIndex].created_at).format("DD/MM/YYYY"),
      },
    },
  ];

  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <MUIDataTable data={reviews} columns={columns} options={options} />
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  reviews: PropTypes.array.isRequired,
};

export default Results;
