import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import * as Http from "src/services/Http";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  FormGroup,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Page from "src/components/Page";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toUpper } from "lodash";
import { useParams } from "react-router-dom";
import ScreenLoader from "src/components/ScreenLoader";
import { AttachFile, Close } from "@material-ui/icons";
import { Delete } from "react-feather";

const typoStyle = {
  // paddingLeft: "50px",
  // fontWeight: "bold",
  textTransform: "uppercase",
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  fieldGroup: {
    marginBottom: theme.spacing(3),
  },
  editor: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    "& .ck-editor__main": {
      minHeight: "200px",
    },
  },
  attachmentContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  attachmentIcon: {
    marginRight: theme.spacing(1),
  },
  attachmentLabel: {
    flex: 1,
  },
  disabledButton: {
    // pointerEvents: "none",
    border: "1px solid gray !important",
    cursor: "no-drop",
  },
}));

const UserDetail = (props) => {
  const classes = useStyles();
  const [apiData, setApiData] = useState({});

  const [dateData, setDateData] = useState(apiData.cremation_date || "");

  const [checkListLeft, setCheckListLeft] = useState([]);
  const [checkListRight, setCheckListRight] = useState([]);
  const [chkdisableButton, setChkdisableButton] = useState(true);
  // new added
  const [archiveDisable, setArchiveDisable] = useState(true);

  const [allButtonDisable, setAllButtonDisable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  const [deliveryValidate, setDiveryValidate] = useState(false)
  const [errorMessage, setErrorMessage] = useState(""); // State to hold the error message


  const [
    cremationDateUnseletedDialog,
    setCremationDateUnseletedDialog,
  ] = useState(false);

  const [Loading, setLoading] = useState(false);

  const [applicationType, setApplicationType] = useState("");

  // const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();
  let userId = "";

  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  // const [isZoomed, setIsZoomed] = useState(false);
  // const [isZoomed2, setIsZoomed2] = useState(false);

  const [readonly, setReadonly] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openDeceasedImg, setOpenDeceasedImg] = React.useState(false);

  const handleClickOpenDeceasedImg = () => {
    setOpenDeceasedImg(true);
  };

  const handleCloseDeceasedImg = () => {
    setOpenDeceasedImg(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const app_id = apiData.app_id;

  const initialValues = {
    notes: apiData?.status_note || "",
    docFormAttachment: null,
  };

  const validationSchema = Yup.object().shape({
    notes: Yup.string(),
    docFormAttachment: Yup.mixed().test(
      "fileType",
      "Invalid file type",
      (value) => {
        if (!value) return true; // Allow empty field
        const allowedTypes = [
          "application/pdf",
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/svg+xml",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
        ];
        return value && value.type && allowedTypes.includes(value.type);
      }
    ),
  });

  const isButtonEnabled = dateData !== "";

  const fetchUserList = async () => {
    try {
      setLoading(true);

      await Http.call("POST", "admin/status_mgmt_details", {
        device_id: userId,
      }).then(async (response) => {
        const newApiData = {
          ...response.data,
          time_of_death: formatDateTimeLocal(response.data.time_of_death),
          // new date picker
          cremation_date: formatDateTimeLocal(response.data.cremation_date),
        };
        //setApiData(response.data);
        setApiData(newApiData);
        setApplicationType(response.data.application_type);

        if (response.data.app_status === 1 || response.data.app_status === 2) {
          setReadonly(true);
          setAllButtonDisable(true);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching user list:", error.message);
    }
  };

  const checkListData = async () => {
    try {
      setLoading(true);

      const response = await Http.call("GET", "admin/status_mgmt");
      const data = await response.data;
      // Split the data into two equal parts
      const middleIndex = Math.ceil(data.length / 2);
      setCheckListLeft(data.slice(0, middleIndex));
      setCheckListRight(data.slice(middleIndex, data.length));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchUserList();
    checkListData();
  }, []);

  const handleComplete = async (values) => {
    try {
      setLoading(true);

      const response = await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        status_note: values.notes,
        status: 1,
      });
      // console.log("response:::", response);
      fetchUserList();
      setReadonly(true);
      setLoading(false);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleArchive = async (values) => {
    try {
      setLoading(true);

      const response = await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        status_note: values.notes,
        status: 2,
      });
      console.log("response:::", response);
      fetchUserList();
      setReadonly(true);
      setLoading(false);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleSave = async (values, setSubmitting) => {
    try {
      setLoading(true);
      let formData = new FormData();
      console.log("response:::", values.docFormAttachment);
      formData.append("docFormAttachment", values.docFormAttachment);
      formData.append("app_id", app_id);
      formData.append("status_note", values.notes);
      const response = await Http.call(
        "POST",
        "admin/update_status",
        formData,
        false,
        true
      );
      console.log("response:::", values);
      fetchUserList();
      setSubmitting(false);
      setSelectedFilesCount(0);
      setLoading(false);
    } catch (error) {
      console.error("Error updating status:", error.message);
    }
  };

  const handleDelete = async (index) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/status_mgmt/delete_status_docsument", {
        app_id: app_id,
        doc_id: index,
      }).then(async (response) => {
        fetchUserList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // const isChecked = (itemId) => {
  //   return (
  //     Array.isArray(apiData.application_status) &&
  //     apiData.application_status.includes(itemId)
  //   );
  // }

  const isChecked = (itemId) => {
    if (apiData && Array.isArray(apiData.application_status)) {
      if (apiData.application_status.includes(19)) {
        setChkdisableButton(false);
      }
      // new added
      if (apiData.application_status.includes(23)) {
        setArchiveDisable(false);
      }
      return apiData.application_status.includes(itemId);
    } else {
      console.log("Data not yet available");
      return false;
    }
    // return (
    //   Array.isArray(apiData.application_status) &&
    //   apiData.application_status.includes(itemId)
    // );
  };

  const handleSelectChange = async (event) => {
    try {
      const selectedType = event.target.value;
      setApplicationType(selectedType);
      const response = await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        application_type: selectedType,
      });
      // console.log("API response:", response);
      const updatedApiData = {
        ...apiData,
        last_changes_date: response.data.last_changes_date,
      };
      setApiData(updatedApiData);
    } catch (error) {
      console.error("Error updating application type:", error.message);
    }
  };

  const handleCheckboxChange = async (itemId, itemChk, formattedDate) => {
    try {
      if (itemId === 14 && itemChk === true && apiData?.cremation_date == "") {
        // Show the dialog box if no cremation date is set
        setCremationDateUnseletedDialog(true);
        return; // Exit early, don't proceed with the rest of the function
      }

      if (itemId === 19 && itemChk === true) {
        handleDialogOpen();
      }
      if (itemId === 19 && itemChk === false) {
        setChkdisableButton(true);
      }
      if (itemId === 23 && itemChk === true) {
        CancelDialogOpen();
      }
      if (itemId === 23 && itemChk === false) {
        setArchiveDisable(true);
      }
      

      const newApiData = {
        ...apiData,
        application_status: apiData.application_status.includes(itemId)
          ? apiData.application_status.filter((id) => id !== itemId)
          : [...apiData.application_status, itemId],
      };

      setApiData(newApiData);
      const response = await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        status_id: itemId,
        status_chk: itemChk ? 1 : 0,
      });

      // const newApiData_forDate = {
      //   ...apiData,
      //   ...newApiData,
      //   last_changes_date: response.data.last_changes_date,
      // };
      // setApiData(newApiData_forDate);

       // Check the response status
    if (response.status === 200) {
      const newApiData_forDate = {
        ...apiData,
        ...newApiData,
        last_changes_date: response.data.last_changes_date,
      };
      setApiData(newApiData_forDate);
    } else if (response.status === 400) {
      // Handle status code 400
      setErrorMessage(response.message || "An error occurred.");
      handleDeliveryDialogOpen();
      console.log(`${response.status} : ${response.message}`); // You can replace this with a more user-friendly message or component
    } else {
      // Handle other status codes if needed
      console.error(`Unexpected response status: ${response.status}`);
    }

      
    } catch (error) {
      console.error("Error updating application status:", error.message);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = async () => {
    setDialogOpen(false);
    if (isChecked(19)) {
      await handleCheckboxChange(19, false);
    }
  };

  // ---------DeliveryDialog----------- 
  const handleDeliveryDialogOpen = () => {
    setDiveryValidate(true);
  };
  const handleDeliveryDialogClose = async () => {
    setDiveryValidate(false);
    // if (isChecked(18)) {
      // await handleCheckboxChange(18, false);
      await fetchUserList();
    // }
  };


  const handleCremationDateDialogClose = async () => {
    setCremationDateUnseletedDialog(false);
    if (isChecked(14)) {
      await handleCheckboxChange(14, false);
    }
  };

  const handleDialogConfirm = async () => {
    if (!isChecked(19)) {
      await handleCheckboxChange(19, true);
    } else if (isChecked(23)) {
      await handleCheckboxChange(23, false);
    }
    // Close the dialog
    // handleDialogClose();
    setDialogOpen(false);
  };

  const handleDialogCancel = async () => {
    if (isChecked(19)) {
      setLoading(true);
      await handleCheckboxChange(19, false);
      setLoading(false);
    }
    // Close the dialog
    // handleDialogClose();
    setDialogOpen(false);
  };

  // -----id=23---- \\
  const CancelDialogOpen = () => {
    setCancelDialog(true);
  };

  const CancelDialogClose = async () => {
    setCancelDialog(false);
    if (isChecked(23)) {
      await handleCheckboxChange(23, false);
    }
  };
  const handleCancelDialogConfirm = async () => {
    if (!isChecked(23)) {
      await handleCheckboxChange(23, true);
    } else if (isChecked(19)) {
      await handleCheckboxChange(19, false);
    }
    // Close the dialog
    // handleDialogClose();
    setCancelDialog(false);
  };
  const handleCancelDialogCancel = async () => {
    if (isChecked(23)) {
      setLoading(true);
      await handleCheckboxChange(23, false);
      setLoading(false);
    }
    // Close the dialog
    // handleDialogClose();
    setCancelDialog(false);
  };

  const handleDateTimeChange = async (event) => {
    try {
      const dateTimeValue = event.target.value;
      // const newApiData = {
      //   ...apiData,
      //   time_of_death: formatDateForServer(dateTimeValue),
      //   last_changes_date: response.data.last_changes_date,
      // };
      // setApiData(newApiData);

      // console.log(newApiData, "newApiData");

      const response = await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        time_of_death: dateTimeValue,
      });
      // console.log("dateTimeValue:::", dateTimeValue);
      // console.log("API response:", response);
      const newApiData = {
        ...apiData,
        time_of_death: formatDateForServer(dateTimeValue),
        last_changes_date: response.data.last_changes_date,
      };
      setApiData(newApiData);

      // console.log(newApiData, "newApiData");
    } catch (error) {
      console.error("Error updating time of death:", error.message);
    }
  };

  const handleCremationDateSave = async (values) => {
    try {
      setLoading(true);
      // const dateTimeValue = event.target.value;
      const dateTimeValue = apiData.cremation_date;
      await Http.call("POST", "admin/update_status", {
        app_id: app_id,
        status_id: 14,
        status_chk: 1,
        cremation_date: dateTimeValue,
      }).then(async (response) => {
        const newApiData = {
          ...apiData,
          cremation_date: formatDateForServer(dateTimeValue),
          cremation_date: dateTimeValue,
          application_status: [...apiData?.application_status, 14],
          last_changes_date: response.data.last_changes_date,
        };
        setApiData(newApiData);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error updating time of death:", error.message);
    }
  };

  const handleCremationDateChange = async (event) => {
    const dateTimeValue = event.target.value;
    // Format date-time for display
    const formattedDateTime = await formatDateTimeLocal(dateTimeValue);
    await setDateData(dateTimeValue);
    await setApiData((prevApiData) => ({
      ...prevApiData,
      cremation_date: dateTimeValue,
    }));
  };

  // Function to format date string to datetime-local format
  const formatDateTimeLocal = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is undefined or null
    const dateTimeParts = dateString.split(" ");
    if (dateTimeParts.length !== 3) return ""; // Check if the format is as expected
    const [datePart, timePart, meridiem] = dateTimeParts;
    const [month, day, year] = datePart.split("-");
    const [hours, minutes, seconds] = timePart.split(":");

    // Convert hours to 24-hour format
    let formattedHours = hours;
    if (meridiem.toLowerCase() === "pm" && hours !== "12") {
      formattedHours = String(parseInt(hours, 10) + 12);
    } else if (meridiem.toLowerCase() === "am" && hours === "12") {
      formattedHours = "00";
    }

    const formattedDate = `${year}-${month}-${day}T${formattedHours}:${minutes}`;
    return formattedDate;
  };

  const formatDateForServer = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if single digit
    const day = date
      .getDate()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    const hours = date
      .getHours()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0"); // Add leading zero if single digit
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Fernwood Cemetery pre-quotes">
      <Grid
        container
        style={{
          // padding: 25,
          backgroundSize: "cover",
          zIndex: 0,
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          enableReinitialize={true}
          validateOnChange
          onSubmit={(values, { resetForm, setSubmitting }) => {
            // handleComplete(values);
            handleSave(values, setSubmitting);
            // console.log("values", values);
            resetForm();
          }}
        >
          {({ errors, touched, values, setSubmitting, setFieldValue }) => (
            <Form className="status-form">
              <Card>
                <CardContent>
                  <div className="status-f">
                    <div className="status-main">
                      {/* First Grid: Created By */}
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          CREATED BY :
                        </Typography>
                      </div>
                      {/* Second Grid: Username, Email, Phone, Location, Logo */}
                      <div className="status-row">
                        <div>
                          <Typography align="left" color="textPrimary">
                            {apiData?.first_name} {apiData?.last_name}
                          </Typography>
                        </div>
                        <div>
                          <Typography align="left" color="textPrimary">
                            {apiData?.email}
                          </Typography>
                        </div>
                        <div>
                          <Typography align="left" color="textPrimary">
                            {apiData?.mobile}
                          </Typography>
                        </div>
                        <div>
                          <Typography align="left" color="textPrimary">
                            {apiData?.user_address}
                          </Typography>
                        </div>
                        <div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              overflowX: "hidden",
                            }}
                          >
                            <img
                              src="/img/fi-rs-portrait.png"
                              alt="Logo"
                              onClick={apiData.image ? handleClickOpen : null}
                              style={{
                                cursor: apiData.image ? "pointer" : "default",
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              // maxWidth="md"
                              // fullWidth
                            >
                              <DialogContent>
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  onClick={handleClose}
                                  aria-label="close"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                  }}
                                >
                                  <Close />
                                </IconButton>
                                {apiData.image && (
                                  <img
                                    src={apiData.image}
                                    alt="Zoomed Logo"
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      maxHeight: "80vh",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                              </DialogContent>
                            </Dialog>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Third Grid: Creation Date */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          CREATION DATE :
                        </Typography>
                      </div>
                      <div className="status-row">
                        <Typography align="left" color="textPrimary">
                          {apiData.created_at}
                        </Typography>
                      </div>
                    </div>

                    {/* Fourth Grid: Recipient of Services */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          RECIPIENT OF SERVICES :
                        </Typography>
                      </div>
                      <div className="status-row">
                        <Typography align="left" color="textPrimary">
                          {apiData.receipt_of_service}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CardContent>

                {/* Divider */}
                <Divider />

                {/* Second CardContent */}
                <CardContent>
                  <div className="status-f">
                    {/* First Row: Service Type */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          Service Type:
                        </Typography>
                      </div>
                      <div className="status-row">
                        <div>
                          {/* Dropdown menu for service type */}
                          <FormControl>
                            <Select
                              labelId="service-type-select-label"
                              id="service-type-select"
                              value={applicationType}
                              disabled={readonly}
                              onChange={handleSelectChange}
                            >
                              <MenuItem value="2">Pre-Need</MenuItem>
                              <MenuItem value="1">At-Need</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div>
                          <Typography
                            align="right"
                            color="textSecondary"
                            style={typoStyle}
                          >
                            Last Changed: {apiData.last_changes_date}
                          </Typography>
                        </div>
                      </div>
                    </div>

                    {/* Second Row: Deceased */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          Deceased :
                        </Typography>
                      </div>
                      <div className="status-row status-deceased-main ">
                        <div className="deceased-name">
                          {/* Deceased name */}
                          <Typography align="left" color="textPrimary">
                            {apiData.deceased_name}
                          </Typography>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            // width: "100%",
                            height: "100%",
                            overflowX: "hidden", //in this chnage and edge:"start" horiziontal remove
                          }}
                        >
                          <img
                            src="/img/fi-rs-portrait.png"
                            alt="Logo"
                            onClick={
                              apiData.deceased_image
                                ? handleClickOpenDeceasedImg
                                : null
                            }
                            style={{
                              cursor: apiData.deceased_image
                                ? "pointer"
                                : "default",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <Dialog
                            open={openDeceasedImg}
                            onClose={handleCloseDeceasedImg}
                            // maxWidth="md"
                            // fullWidth
                          >
                            <DialogContent>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseDeceasedImg}
                                aria-label="close"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <Close />
                              </IconButton>
                              {apiData.deceased_image && (
                                <img
                                  src={apiData.deceased_image}
                                  alt="Zoomed Logo"
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    maxHeight: "80vh",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </DialogContent>
                          </Dialog>
                        </div>

                        {/* City Name */}
                        {/* <div>
                          <Typography
                            align="left"
                            color="textPrimary"
                            style={typoStyle}
                          >
                            {apiData.deceased_city}
                          </Typography>
                        </div> */}
                      </div>
                    </div>

                    {/* Third Row: Time and Date */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          Time of Death:
                        </Typography>
                      </div>
                      <div className="status-row">
                        {/* Date and Time input */}
                        <TextField
                          id="datetime-local"
                          disableFuture
                          disabled={readonly}
                          type="datetime-local"
                          value={
                            apiData.time_of_death ? apiData.time_of_death : ""
                          }
                          inputProps={{
                            max:
                              new Date().toISOString().split("T")[0] + "T23:59", // Set maximum date to today
                          }}
                          onChange={(e) => handleDateTimeChange(e)}
                          // fullWidth
                        />
                      </div>
                    </div>

                    {/* fourth Row: cremation Time and Date */}
                    <div className="status-main">
                      <div className="status-title">
                        <Typography
                          align="left"
                          color="textPrimary"
                          style={typoStyle}
                        >
                          cremation date:
                        </Typography>
                      </div>
                      <div className="status-row">
                        {/* Date and Time input */}
                        <TextField
                          id="datetime-local"
                          disablePast
                          disabled={readonly}
                          type="datetime-local"
                          // value={
                          //   apiData.cremation_date ? apiData.cremation_date : ""
                          // }
                          value={dateData ? dateData : apiData.cremation_date}
                          inputProps={{
                            min:
                              new Date().toISOString().split("T")[0] + "T23:59", // Set maximum date to today
                          }}
                          // onChange={(e) => handleCremationDateSave(e)}
                          onChange={(e) => handleCremationDateChange(e)}
                          // fullWidth
                        />
                        <Button
                          color="primary"
                          type="button"
                          variant="contained"
                          disabled={
                            allButtonDisable || !userId || !isButtonEnabled
                          }
                          onClick={handleCremationDateSave}
                        >
                          SAVE CREMATION DATE
                        </Button>
                        <Box />
                        <Box />
                        <Box />
                        <Box />
                        <Box />
                        <Box />
                        <Box />
                        <Box />
                        
                      </div>
                    </div>

                    {/* Checkbox Group */}
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      style={{
                        whiteSpace: "nowrap",
                        justifyContent: "space-around",
                      }}
                    >
                      <Grid item md={6} xs={6}>
                        {Array.isArray(checkListLeft) &&
                        checkListLeft.length > 0 ? (
                          checkListLeft.map((item, index) => (
                            <Grid item md={6} xs={6} key={index}>
                              <FormControl component="fieldset" fullWidth>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isChecked(item.id)}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item.id,
                                            e.target.checked
                                          )
                                        }
                                        disabled={readonly}

                                        // disabled={item.id === 19 ? true : false}
                                      />
                                    }
                                    label={item.name}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <p>Loading...</p>
                          </Grid>
                        )}
                      </Grid>

                      <Grid item md={6} xs={6}>
                        {Array.isArray(checkListRight) &&
                        checkListRight.length > 0 ? (
                          checkListRight.map((item, index) => (
                            <Grid item md={3} xs={6} key={index}>
                              <FormControl component="fieldset" fullWidth>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isChecked(item.id)}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item.id,
                                            e.target.checked
                                          )
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        // disabled={item.id === 19 ? true : false}
                                        disabled={readonly}
                                      />
                                    }
                                    label={item.name}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12}>
                            <p>Loading...</p>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {/* notes content  */}
                    <Grid item xs={12}>
                      {/* {apiData.status_note && (
                    )} */}

                      <Field name="notes">
                        {({ field }) => (
                          <div>
                            <TextField
                              {...field}
                              id="notes"
                              label="Notes"
                              fullWidth
                              multiline
                              // value={
                              //   values.notes !== ""
                              //     ? values.notes
                              //     : apiData.status_note || ""
                              // }
                              // onChange={field.onChange}  //bcoz formik will handle already (intailvalue)
                              rows={4}
                              variant="outlined"
                              disabled={allButtonDisable}
                            />
                            <ErrorMessage
                              name="notes"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12} className={classes.attachmentContainer}>
                      <Box display="flex" alignItems="center">
                        <AttachFile className={classes.attachmentIcon} />
                        <Typography className={classes.attachmentLabel}>
                          Attachments:
                        </Typography>
                      </Box>
                      <input
                        type="file"
                        name="docFormAttachment"
                        disabled={
                          allButtonDisable ||
                          (apiData &&
                            apiData.documents &&
                            apiData.documents.length === 5)
                        }
                        onChange={(event) => {
                          const file = event.target.files[0]; // Get the first selected file
                          const fileName = file ? file.name : ""; // Store the file name
                          setFieldValue("docFormAttachment", file); // Store the selected file name
                          setSelectedFilesCount(fileName ? 1 : 0); // Update
                        }}
                        accept="application/pdf,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                        style={{
                          display: "none",
                        }}
                        id="file-input"
                        error={
                          touched.docFormAttachment &&
                          Boolean(errors.docFormAttachment)
                        }
                        helperText={
                          touched.docFormAttachment && errors.docFormAttachment
                        }
                      />
                      <label htmlFor="file-input">
                        <Button
                          variant="outlined"
                          color="primary"
                          component="span"
                          // className={classes.attachmentButton}
                          className={
                            apiData &&
                            apiData.documents &&
                            apiData.documents.length === 5
                              ? classes.disabledButton
                              : classes.attachmentButton
                          }
                        >
                          Upload File
                        </Button>
                      </label>
                      <Typography style={{ paddingLeft: "5px" }}>
                        {selectedFilesCount} file selected
                      </Typography>
                      {errors.docFormAttachment &&
                        touched.docFormAttachment && (
                          <div className="error">
                            {errors.docFormAttachment}
                          </div>
                        )}
                    </Grid>

                    <List>
                      {apiData &&
                        Array.isArray(apiData.documents) &&
                        apiData.documents.map((file, index) => (
                          <ListItem key={index}>
                            <a href={file.doc_path} target="_blank" download>
                              <ListItemText primary={file.filename} />
                            </a>
                            <IconButton
                              disabled={
                                allButtonDisable
                                //  ||
                                // (apiData &&
                                //   apiData.documents &&
                                //   apiData.documents.length === 5)
                              }
                              onClick={() => handleDelete(file.id)}
                            >
                              <Delete />
                            </IconButton>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </CardContent>
                <Divider />

                <Dialog
                  open={dialogOpen}
                  onClose={() => handleDialogClose(false)}
                >
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to proceed?
                  </DialogContent>
                  <DialogActions>
                    <Button
                      // onClick={() => handleDialogClose(true)}
                      onClick={handleDialogConfirm}
                      color="primary"
                    >
                      Confirm
                    </Button>
                    <Button
                      // onClick={() => handleDialogClose(true)}
                      onClick={handleDialogCancel}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* ---id=23--- */}
                <Dialog
                  open={cancelDialog}
                  onClose={() => CancelDialogClose(false)}
                >
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                    Are you sure you want to proceed?
                  </DialogContent>
                  <DialogActions>
                    <Button
                      // onClick={() => handleDialogClose(true)}
                      onClick={handleCancelDialogConfirm}
                      color="primary"
                    >
                      Confirm
                    </Button>
                    <Button
                      // onClick={() => handleDialogClose(true)}
                      onClick={handleCancelDialogCancel}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* --id=14-- */}
                <Dialog
                  open={cremationDateUnseletedDialog}
                  onClose={() => handleCremationDateDialogClose(false)}
                >
                  {/* <DialogTitle>Cremation Date Selection</DialogTitle> */}
                  <DialogContent>
                    Please select a Cremation Date first.
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCremationDateDialogClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* --id=17 & 18-- */}
                <Dialog
                  open={deliveryValidate}
                  onClose={() => handleDeliveryDialogClose(false)}
                >
                  {/* <DialogTitle>Cremation Date Selection</DialogTitle> */}
                  <DialogContent>
                  {errorMessage}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleDeliveryDialogClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>


                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={allButtonDisable || !userId}
                    // onClick={() => {
                    //   setSubmitting(true)
                    //   handleSave(values,setSubmitting)}}
                  >
                    SAVE
                  </Button>
                  <Box m={1} />
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={(apiData.app_status===2) ? allButtonDisable : chkdisableButton}
                    disabled={
                      apiData.app_status === 1 || apiData.app_status === 2
                        ? allButtonDisable
                        : chkdisableButton
                    }
                    type="button"
                    onClick={() => {
                      setSubmitting(true);
                      handleComplete(values);
                    }}
                  >
                    COMPLETE
                  </Button>
                  <Box m={1} />
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={chkdisableButton}
                    // disable={props.allButtonDisable ? props.allButtonDisable : chkdisableButton }

                    // disabled={
                    //   apiData.app_status === 1 || apiData.app_status === 2
                    //     ? allButtonDisable
                    //     : chkdisableButton
                    // }
                    // new added
                    disabled={
                      apiData.app_status === 1 || apiData.app_status === 2
                        ? allButtonDisable
                        : archiveDisable
                    }
                    onClick={() => handleArchive(values)}
                    type="button"
                  >
                    ARCHIVE
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
