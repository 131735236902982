import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";

const VendorListView = (props) => {
  const [vendorUserList, setVendorUserList] = useState([]);

  const [Loading, setLoading] = useState(true);
  const authuser = useSelector((state) => state?.session?.user);

  const fetchVederUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/get_vendors");
      setVendorUserList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const multipleVenderDelete = async (data, isContactDelete) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/lookup_dirs/delete", {
        id: data,
        iscontactdelete: isContactDelete,
      }).then(async (response) => {
        fetchVederUserList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchVederUserList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Box>
      <Results
        users={vendorUserList}
        pageLoading={setLoading}
        fetchUser={fetchVederUserList}
        multipleDelete={multipleVenderDelete}
        authuser={authuser}
      />
    </Box>
  );
};

//export default VendorListView;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorListView);
