import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import Page from "src/components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import ConformationDialog from "src/components/ConformationDialog";
import TabPanel from "src/components/TabPanel";
import ReviewSetting from "./ReviewSetting";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Review List", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
  loadingAnimation: {
    color: "gray",
    fontStyle: "italic",
    display: "inline-block",
    "& span": {
      opacity: 0,
      animation: "$blink 1.5s infinite",
    },
    "& span:nth-child(1)": {
      animationDelay: "0s",
    },
    "& span:nth-child(2)": {
      animationDelay: "0.5s",
    },
    "& span:nth-child(3)": {
      animationDelay: "1s",
    },
  },
  "@keyframes blink": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
}));

const ReviewListView = (props) => {
  const classes = useStyles();
  const authuser = useSelector((state) => state?.session?.user);
  const [activeTab, setActiveTab] = useState("step1");

  const [reviewList, setReviewList] = useState({
    id: "Loading",
    customer_name: "Loading",
    email: "Loading",
    remark: "Loading",
    star: "Loading",
    is_allow_public_shared:"Loading",
    status: "Loading",
    created_by: "Loading",
  });
  const [Loading, setLoading] = useState(true);
  const [open, setopen] = useState(false);
  const [deletable, setdeletable] = useState([]);
  const [deletableTitles, setdeletableTitles] = useState([]);
  const handleClose = () => setopen(false);
  const fetchReviewList = async () => {
    try {
      const response = await Http.call("GET", "admin/review");
      setReviewList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setStatus = async (id, status) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/review/" + id, {
        status: status,
        _method: "PUT",
      }).then(async (response) => {
        fetchReviewList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const deleteReview = (idsToDelete, titlesToDelete) => {
    setopen(true);
    setdeletable(idsToDelete);
    setdeletableTitles(titlesToDelete);
  };
  const onConform = async () => {
    try {
      await Http.call("POST", "admin/review/delete", {
        id: deletable,
      }).then(async (response) => {
        props.setSnackbar(true, response.status, response.message);
      });
      fetchReviewList();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchReviewList();
  }, []);

  const TabList = [
    {
      value: "step1",
      lable: "REVIEW SETTING",
      component: (
        <ReviewSetting/>
      ),
    },
    {
      value: "step2",
      lable: "review",
      component: (
        <Results
          setTabval={setActiveTab}
          reviews={reviewList}
          pageLoading={setLoading}
          fetchReview={fetchReviewList}
          setStatus={setStatus}
          deleteReview={deleteReview}
          authuser={authuser}
          // setdisableTab={setDisabledTab}
          // mode={breadcrumb_text}
          // allButtonDisable={allButtonDisable}
        />
      ),
    },
  ];

  const tabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Reviews">
      <Container maxWidth={false}>
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <Box mt={3}>
          <Paper
          //  className={classes.root}
          >
            <Tabs
              value={activeTab}
              onChange={tabChange}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
            >
              {TabList.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab.value}
                  label={tab.lable}
                  disabled={tab.isDisabled} //in this line use , working with active and nonactive tabes
                  style={
                    tab.isHidden ? { display: "none" } : { display: "block" }
                  }
                  {...a11yProps(tab.value)}
                />
              ))}
            </Tabs>
          </Paper>
          <Grid>
            {TabList.map((tab, index) => (
              <TabPanel value={activeTab} index={tab.value} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Grid>
          {/* <Results
            reviews={reviewList}
            pageLoading={setLoading}
            fetchReview={fetchReviewList}
            setStatus={setStatus}
            deleteReview={deleteReview}
            authuser={authuser}
          /> */}
        </Box>
      </Container>
      <ConformationDialog
        classes={{
          paper: classes.paper,
        }}
        id="confirmation-dialog-title"
        keepMounted
        open={open}
        onClose={handleClose}
        onConformAction={onConform}
        value={deletableTitles}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewListView);
