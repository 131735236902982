import React, { useEffect, useState } from "react";
import * as Http from "src/services/Http";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Grid,
  makeStyles,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  CardContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import { setSnackbar } from "src/actions";
// import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Delete, Edit } from "react-feather";
import Add_conatact from "./Add_conatact";
import ContactsListView from "src/views/Contacts/ContactsListView";
import { useNavigate, useParams } from "react-router";
import List_contact from "./List_contact";
import { chkRoutePerm } from "src/services/Helper";
import { Link } from "react-router-dom";
import ContactDetails from "src/views/Counsellor/Fernwood_counsellor_v1/Contact_f/Edit_contact/index";
import ScreenLoader from "src/components/ScreenLoader";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    overflowX: "auto", // Add horizontal scrolling if content overflows
  },
  cell: {
    wordBreak: "break-word",
    maxWidth: "50ch",
    // textAlign:"center",
    // whiteSpace: 'nowrap'
  },
  dashed: {
    textAlign: "center",
  },
  tableContent: {
    width: "max-content",
  },
  tableContainerId: {
    width: "-webkit-fill-available",
  },
  lastBtn: {
    marginLeft: 10,
  },
}));
const Contact_f = (props) => {
  const authuser = useSelector((state) => state?.session?.user);

  const chkRoutPerm = chkRoutePerm("/app/users/edit", authuser);

  const [userList, setUserList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const { id } = useParams(1);
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }

  const classes = useStyles();

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openModal, setOpenModal] = useState(false);
  const [openListModal, setOpenListModal] = useState(false);

  const [openDialog_forEdit, setOpenDialog_forEdit] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleListModalOpen = () => {
    setOpenListModal(true);
  };
  const handleListModalClose = () => {
    setOpenListModal(false);
  };

  // console.log("setAllButtonDisable ok === >",props.allButtonDisable)

  const fetchUserList = async () => {
    try {
      if (userId) {
        setLoading(true);
        const response = await Http.call(
          "GET",
          "admin/application/contacts/" + userId
        );
        setUserList(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = data.map((row) => row.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleCheckboxClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/application/contacts/destroy", {
        id: [id],
        // iscontactdelete: isContactDelete,
      }).then(async (response) => {
        fetchUserList();
        props.setSnackbar(true, response.status, response.message);
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenDialog_forEdit = (userId) => {
    setSelectedUserId(userId);
    setOpenDialog_forEdit(true);
  };

  const handleCloseDialog_forEdit = () => {
    setOpenDialog_forEdit(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page title="Fernwood Cemetery Application Contact">
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="flex-start" py={2}>
            <Grid>
              <Button
                color="primary"
                variant="contained"
                disabled={props.allButtonDisable || !userId}
                onClick={handleModalOpen}
              >
                ADD NEW CONTACT
              </Button>
              <Dialog open={openModal} onClose={handleModalClose}>
                <DialogTitle>Add New Contact</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <Add_conatact
                    onClose={handleModalClose}
                    fetchUserList={fetchUserList}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
            <Grid>
              <Button
                className={classes.lastBtn}
                color="primary"
                variant="contained"
                disabled={props.allButtonDisable || !userId}
                onClick={handleListModalOpen}
              >
                SELECT CONTACT
              </Button>
              <Dialog
                open={openListModal}
                onClose={handleListModalClose}
                maxWidth="md"
              >
                <DialogTitle>CONTACT LIST</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <List_contact
                    id={userId}
                    onClose={handleListModalClose}
                    fetchUserList={fetchUserList}
                  />
                </DialogContent>
              </Dialog>
            </Grid>
          </Box>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              className={!userList ||
                userId === undefined || userList?.length === 0
                  ? classes.tableContainerId
                  : classes.tableContent
              }
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">ROLE</TableCell>
                  <TableCell align="center">NAME</TableCell>
                  <TableCell align="center">COMPANY</TableCell>
                  <TableCell align="center">EMAIL</TableCell>
                  <TableCell align="center">PHONE</TableCell>
                  <TableCell align="center">FAX</TableCell>
                  <TableCell align="center">MAILING ADDRESS</TableCell>
                  <TableCell align="center">NOTES</TableCell>
                  <TableCell align="center">ACTIONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!userList ||userList?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  userList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell className={classes.cell}>
                          {row.position_title ? (
                            row.position_title
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.user_name ? (
                            row.user_name
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.company_name ? (
                            row.company_name
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.email ? (
                            row.email
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.mobile ? (
                            row.mobile
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.fax ? (
                            row.fax
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.address ? (
                            row.address
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {row.notes ? (
                            row.notes
                          ) : (
                            <Typography className={classes.dashed}>
                              —
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleDelete(row.id)}
                            aria-label="delete"
                            disabled={row.is_disable}
                          >
                            <Delete />
                          </IconButton>

                          <IconButton
                            aria-label="edit"
                            disabled={row.is_disable}
                            // component={Link}
                            // to={"/app/contacts/" + row.user_id}

                            onClick={() =>
                              handleOpenDialog_forEdit(row.user_id)
                            }
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>

            <Dialog
              BackdropProps={{
                style: {
                  opacity: "0.2",
                },
              }}
              open={openDialog_forEdit}
              onClose={handleCloseDialog_forEdit}
            >
              {/* <DialogTitle>Edit Apllication</DialogTitle> */}
              <DialogContent className={classes.dialogContent}>
                <ContactDetails
                  userid={selectedUserId}
                  onCloseDialog={handleCloseDialog_forEdit}
                  navigateId={userId}
                  fetchUserList={fetchUserList}
                />
              </DialogContent>
            </Dialog>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              // component="div"
              count={userList?.length ? userList?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact_f);
