import React, { useState, useEffect } from "react";
import { Container, Grid, Card, makeStyles, Dialog } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Page from "src/components/Page";
import ReviewFormDetails from "../Components/ReviewFormDetails";
import Toolbar from "../Components/Toolbar";
import { setSnackbar } from "src/actions";
import * as Http from "src/services/Http";
import { connect } from "react-redux";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import { useNavigate } from "react-router";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Review", url: "/app/reviews" },
  { name: "Add Review", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // minHeight: "100%",
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  QuesDetail: {
    marginTop: theme.spacing(3),
  },
}));

const AddReview = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    user_id: "",
    // star: "",
    // remark: "",
  });
  const navigate = useNavigate();

  const [userList, setUserList] = useState([]);
  const close = () => navigate(-1);

  const fetchUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/lookup_dirs");
      setUserList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  if (Loading) {
    return (
      <Card style={{ height: 300 }}>
        <LinearProgress />
      </Card>
    );
  }
  return (
    <Dialog open onClose={close} className="reviewAdd" >
      <Page className={classes.root}  title="Add Review">
         {/* <Container maxWidth="lg"> */}
        {/* <BreadcrumbsAdmin urlList={breadUrlList} /> */}
        {/* <Toolbar mode={"Add"} /> */}
        {/* <Grid container spacing={3} className={classes.QuesDetail}> */}
        <Grid
          item
          style={{ width: "700px" }}
        >
        <ReviewFormDetails
          formValues={values}
          setvalues={setValues}
          userlists={userList}
          handleChange={handleChange}
          mode={"Add"}
          setSnackbar={props.setSnackbar}
        />
        </Grid>
        {/* </Grid> */}
        {/* </Container> */}
      </Page>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReview);
