import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  Box,
} from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const ShippingDetails = ({
  formData,
  shipmentTypeList,
  saveShippingData,
  ...props
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [editModule, setEditModule] = useState(true);
  const [deliveryAddress, setDeliveryAddress] = useState({
    house_name:"",
    address_line_1: "",
    locationAddress: "",
    lat: "",
    long: "",
    city: "",
    state: "",
    state_code: "",
    country: "",
    country_code: "",
    zip: "",
  });

  const initialValues = formData;

  const validationSchema = Yup.object().shape({
    shippmentType: Yup.string().required("Shippment type is required"),
    recipient: Yup.string().required("Recipient is required"),
    shipmentAddress: Yup.string().when("shippmentType", {
      is: "189",
      then: Yup.string().required("shipmentAddress is required"),
    }),
    weight: Yup.number().when("shippmentType", {
      is: "189",
      then: Yup.number()
        .required("Weight is required")
        .positive("Weight must be a positive number")
        .integer("Weight must be an integer"),
    }),
    sheduleCollectionDate: Yup.date().when("shippmentType", {
      is: "188",
      then: Yup.date().required("Scheduled Collection Date is required"),
    }),
    dateCollected: Yup.date().when("shippmentType", {
      is: "188",
      then: Yup.date()
        // .required("Date Collected is required")
        .min(
          Yup.ref("sheduleCollectionDate"),
          "Date Collected must be after Scheduled Collection Date"
        ),
    }),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        console.log("Form submitted with values:", values);
        const data = {
          ...values,
          sheduleCollectionDate:
            values.sheduleCollectionDate !== ""
              ? props.formatDateForServer(values.sheduleCollectionDate)
              : null,
          dateCollected:
            values.dateCollected !== ""
              ? props.formatDateForServer(values.dateCollected)
              : null,
          shipmentAddress: deliveryAddress,
        };
        await saveShippingData(data);

        resetForm();
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        setFieldValue,
        touched,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Shipment Type:</Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="shippmentType"
                autoWidth={true}
                value={values.shippmentType}
                onChange={(e) => {
                  setFieldValue("shippmentType", e.target.value);
                  if(e.target.value.toString() ==='189'){
                    setFieldValue("sheduleCollectionDate", '');
                    setFieldValue("dateCollected", '');
                    setFieldValue("shipmentAddress", values.shipmentAddress);
                    setFieldValue("weight", values.weight);
                  }else{
                    setFieldValue("sheduleCollectionDate", values.sheduleCollectionDate);
                    setFieldValue("dateCollected", values.dateCollected);
                    setFieldValue("shipmentAddress", '');
                    setFieldValue("weight", '');
                  }
                }}
                disabled={editModule ? props.isShipmentDone === 1 : editModule}
                fullWidth
              >
                {shipmentTypeList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Recipient:</Typography>
              <TextField
                fullWidth
                name="recipient"
                value={values.recipient}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.shipmentId && !!errors.shipmentId}
                helperText={touched.shipmentId && errors.shipmentId}
                disabled={editModule ? props.isShipmentDone === 1 : editModule}
              />
            </Grid>

            {values.shippmentType?.toString() === "189" ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Address:</Typography>
                  <TextField
                    name="shipmentAddress"
                    fullWidth
                    color="primary"
                    placeholder="enter address"
                    // required
                    disabled={editModule ? props.isShipmentDone === 1 : editModule}
                    InputProps={{
                      inputComponent: ({
                        inputRef,
                        onFocus,
                        onBlur,
                        ...props
                      }) => (
                        <Autocomplete
                          {...props}
                          apiKey={"AIzaSyAW9PMZKxsJ3iAZ249oFM6k8M1xYKhMXhw"}
                          onPlaceSelected={(place) => {
                            console.log(place, "place");
                            let state,
                              city,
                              zip,
                              country,
                              state_code,
                              country_code = "";
                            place.address_components.length > 0 &&
                              place.address_components.map(
                                (address_component) => {
                                  if (
                                    address_component.types[0] ===
                                    "administrative_area_level_1"
                                  ) {
                                    state = address_component.long_name;
                                    state_code = address_component.short_name;
                                  } else if (
                                    address_component.types[0] === "locality"
                                  ) {
                                    city = address_component.long_name;
                                  } else if (
                                    address_component.types[0] === "postal_code"
                                  ) {
                                    zip = address_component.long_name;
                                  } else if (
                                    address_component.types[0] === "country"
                                  ) {
                                    country = address_component.long_name;
                                    country_code = address_component.short_name;
                                  }
                                }
                              );
                            setDeliveryAddress({
                              ...deliveryAddress,
                              house_name:place.name,
                              address_line_1:
                                // place.name + " " + 
                                place.formatted_address,
                              locationAddress:
                                place.name + " " + place.formatted_address,
                              lat: place.geometry.location.lat(),
                              long: place.geometry.location.lng(),
                              city: city,
                              state: state,
                              state_code: state_code,
                              country: country,
                              country_code: country_code,
                              zip:zip,
                            });

                            setFieldValue(
                              "shipmentAddress",
                              place.name + " " + place.formatted_address
                            );
                          }}
                          options={{
                            types: ["geocode", "establishment"],
                            fields: ["ALL"],
                          }}
                          defaultValue={values.shipmentAddress}
                        />
                      ),
                    }}
                  />
                </Grid>

                {values.trackingId ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">Shipment ID:</Typography>
                      <TextField
                        fullWidth
                        name="trackingId"
                        value={values.trackingId}
                        onChange={handleChange}
                        disabled
                        error={touched.shipmentId && !!errors.shipmentId}
                        helperText={touched.shipmentId && errors.shipmentId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Order Placed On:</Typography>
                      <TextField
                        fullWidth
                        name="shipmentOrderDate"
                        value={values.shipmentOrderDate}
                        onChange={handleChange}
                        // variant="outlined"
                        disabled
                        // margin="normal"
                        error={touched.orderPlacedOn && !!errors.orderPlacedOn}
                        helperText={
                          touched.orderPlacedOn && errors.orderPlacedOn
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Status:</Typography>
                      <TextField
                        fullWidth
                        disabled
                        name="shipmentStatus"
                        value={values.shipmentStatus}
                        onChange={handleChange}
                        error={touched.status && !!errors.status}
                        helperText={touched.status && errors.status}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    {!editMode && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setEditMode(true)}
                      >
                        Add Weight
                      </Button>
                    )}
                  </Grid>
                )}
                {editMode && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Weight:</Typography>
                    <TextField
                      fullWidth
                      name="weight"
                      value={values.weight}
                      required
                      onChange={handleChange}
                      disabled={editModule ? props.isShipmentDone === 1 : editModule}
                      // margin="normal"
                      error={touched.weight && !!errors.weight}
                      helperText={touched.weight && errors.weight}
                    />
                  </Grid>
                )}
              </>
            ) : (
              ""
            )}

            {values.shippmentType?.toString() === "188" ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Scheduled Collection Date:
                  </Typography>
                  <TextField
                    fullWidth
                    name="sheduleCollectionDate"
                    value={values.sheduleCollectionDate}
                    onChange={handleChange}
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sheduleCollectionDate && !!errors.sheduleCollectionDate}
                    helperText={
                      touched.sheduleCollectionDate &&
                      errors.sheduleCollectionDate
                    }
                    disabled={editModule ? props.isShipmentDone === 1 : editModule}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Date Collected:</Typography>
                  <TextField
                    fullWidth
                    name="dateCollected"
                    value={values.dateCollected}
                    type="datetime-local"
                    className={classes.textField}
                    onChange={handleChange}
                    // error={touched.dateCollected && !!errors.dateCollected}
                    error={Boolean(
                        touched.dateCollected && errors.dateCollected
                      )}
                    helperText={touched.dateCollected && errors.dateCollected}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={editModule ? props.isShipmentDone === 1 : editModule}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}

            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                  variant="contained"
                  color="primary"
                  disabled={editModule === false && props.isShipmentDone === 1}
                  onClick={() => setEditModule(false)}
                  style={{ marginRight: "7px" }}
                >
                  Edit
                </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                // disabled={isSubmitting}
                disabled={editModule ? props.isShipmentDone === 1 : editModule}
                // onClick={handleSubmit}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ShippingDetails;
