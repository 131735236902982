// EmailMessageField.js
import React, { memo } from "react";
import { Grid, Box, CardHeader, Typography } from "@material-ui/core";
// import { CKEditor } from "ckeditor4-react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EmailMessageField = memo(
  ({ emailMessage, setEmailMessage, touched, errors ,values}) => {
    // console.log("emailMessage--->>",emailMessage)
    // console.log("VALUE--->>",values)
    return (
      <Grid item xs={12}>
        <Box>
          {/* <CardHeader title="Message" /> */}
          {/* <CKEditor
            name="emailMessage"
            key={emailMessage}
            initData={emailMessage}
            onBlur={(event) => {
              setEmailMessage("emailMessage", event.editor.getData()); // Update message using setFieldValue
            }}
            onChange={(event) => {
              setEmailMessage("emailMessage", event.editor.getData()); // Update message using setFieldValue
            }}
            config={{
              extraPlugins: "docprops",
              removePlugins: "about,image,easyimage",
              fullPage: false,
              allowedContent: true,
              formatTags: "p;h1;h2;h3;pre;span;",
              height: 320,
            }}
          /> */}
          <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        'heading', 'bold', 'italic', 'underline', 'strikethrough', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'undo', 'redo', 'alignment', 'fontColor', 'fontBackgroundColor', 'fontSize', 'fontFamily', 'highlight', 'removeFormat', 'specialCharacters', 'horizontalLine', 'code', 'codeBlock', 'findAndReplace'
                      ],
                      licenseKey: 'OEJDa0oxSDRFcUV1bllOdXA1d0l6UE10d0ttTndRQ0JPbmtRL0JtamRoV0VERzVEY0RCTEVjVXlJVnBwWmc9PS1NakF5TkRFd01ETT0=',
                    }}
                    name="email_message"
                    data={emailMessage}

                    onBlur={(event, editor) => {
                      setEmailMessage("emailMessage", editor.getData()); // Update message using setFieldValue
                    }}
                    onChange={(event, editor) => {
                      setEmailMessage("emailMessage", editor.getData()); // Update message using setFieldValue
                    }}
                  />
          {touched.emailMessage && errors.emailMessage && (
            <Typography variant="body2" color="error">
              {errors.emailMessage}
            </Typography>
          )}
        </Box>
      </Grid>
    );
  }
);

export default EmailMessageField;
