import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Http from "src/services/Http";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActionArea,
  CardActions,
  Divider,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ScreenLoader from "src/components/ScreenLoader";
import Cancelbtn from "src/components/common/Cancelbtn";
import "src/styles/custom.css";
import * as Messages from "src/constants/Messages/index";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    height: 150,
    width: 150,
  },
  input: {
    display: "none",
  },
  media: {
    height: 150,
    display: "inline-block",
  },
}));

const tax_types = [
  {
    id: 0,
    name: "Percentage",
  },
  {
    id: 1,
    name: "Fixed",
  },
];

const AddUserSchema = Yup.object().shape({
  service_name: Yup.string()
    .min(2, Messages.TOO_SHORT)
    .required(Messages.REQUIRED),
  service_description: Yup.string().required(Messages.REQUIRED),
  //image: Yup.string().required("Required!"),
  category_id: Yup.string().required(Messages.REQUIRED),
  price: Yup.number().required(Messages.REQUIRED),
  placeholder_price: Yup.number(),
  tax_type: Yup.number().required(Messages.REQUIRED),
  taxable_amount: Yup.number().when("tax_type", {
    is: 0,
    then: Yup.number()
      .required(Messages.REQUIRED)
      .min(1, Messages.PERCENTAGE_BETWEEN)
      .max(100, Messages.PERCENTAGE_BETWEEN),
  }),
  // taxable_amount: Yup.number().required(Messages.REQUIRED),
  // .positive()
  // .min(1, Messages.PERCENTAGE_BETWEEN)
  // .max(100, Messages.PERCENTAGE_BETWEEN)

  // taxable_amount: Yup.number().when("tax_type", {
  //   is: 1,
  //   then: Yup.number().required(Messages.REQUIRED),
  // }),
});
const ServicesDetails = ({
  mode,
  setSnackbar,
  formValues,
  addonClass,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [imageError, setimageError] = useState("");
  useEffect(() => {
    const getCategory = async () => {
      let category_url = "";

      if (formValues.id !== undefined) {
        category_url = `admin/servicecategories/${formValues.category_id}`;
      } else {
        category_url = "admin/servicecategories";
      }
      const response = await Http.call("GET", category_url);

      setCategories(response.data);
      setLoading(false);
    };
    getCategory();
  }, [formValues.id, formValues.category_id]);

  const handleUploadClick = async (e, setFieldValue) => {
    setimageError(" ");

    let files = e.target.files || e.dataTransfer.files;

    if (!files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setimageError(
        "This image is not allowed, please upload jpg,jpeg and png image."
      );
      return false;
    }
    if (!files.length) return;
    createImage(files[0], setFieldValue);
  };

  const createImage = (file, setFieldValue) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setFieldValue("image", e.target.result);
      setFieldValue("file", file);
    };
    reader.readAsDataURL(file);
  };
  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        if (values.file === undefined) {
          delete values.image;
          //actions.setFieldValue("image", "");
        }

        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/service";
        } else {
          method = "PATCH";
          url = "admin/service/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/addons");
              setSnackbar(true, status, message);
            } else if (status === 422) {
              actions.setErrors(data);
            } else {
              setSnackbar(true, status, message);
            }
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid item md={12} className={addonClass}>
              <Card>
                <CardHeader title={mode + " Add-on"} />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        name="service_name"
                        label="Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.service_name}
                        error={Boolean(
                          touched.service_name && errors.service_name
                        )}
                        margin="normal"
                        helperText={touched.service_name && errors.service_name}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Description"
                        name="service_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.service_description}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(
                          touched.service_description &&
                            errors.service_description
                        )}
                        multiline
                        rows={3}
                        helperText={
                          touched.service_description &&
                          errors.service_description
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card className={classes.root}>
                        <CardActionArea>
                          <CardMedia
                            height="140"
                            alt="Contemplative Reptile"
                            component="img"
                            className={classes.media}
                            image={
                              values.file !== null && values.file !== undefined
                                ? URL.createObjectURL(values.file)
                                : values.image === ""
                                ? "/static/images/uploade_demo.png"
                                : values.image
                            }
                            title="Contemplative Reptile"
                          />
                        </CardActionArea>
                        <CardActions>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple={false}
                            type="file"
                            onChange={(event) => {
                              handleUploadClick(event, setFieldValue);
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              variant="text"
                              style={{ padding: 0 }}
                            >
                              <label
                                htmlFor="contained-button-file"
                                style={{ padding: 5 }}
                              >
                                Upload picture
                              </label>
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                      <div className="error">{imageError}</div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                    <Grid item md={12}>
                        <TextField
                          fullWidth
                          label="Placeholder Price"
                          margin="normal"
                          name="placeholder_price"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^\d*(\.\d{0,2})?$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("placeholder_price", value);
                            }
                          }}
                          value={values.placeholder_price}
                          type="text"
                          variant="outlined"
                          error={Boolean(touched.placeholder_price && errors.placeholder_price)}
                          helperText={touched.placeholder_price && errors.placeholder_price}
                        />
                      </Grid>

                      <Grid item md={12}>
                        <TextField
                          fullWidth
                          label="Price"
                          margin="normal"
                          name="price"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^\d*(\.\d{0,2})?$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("price", value);
                            }
                          }}
                          value={values.price}
                          type="text"
                          variant="outlined"
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Grid>

                      <Grid item md={12}>
                        <TextField
                          fullWidth
                          label="Tax Type"
                          name="tax_type"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.tax_type}
                          margin="normal"
                          variant="outlined"
                          error={Boolean(touched.tax_type && errors.tax_type)}
                          helperText={touched.tax_type && errors.tax_type}
                        >
                          {tax_types.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={12}>
                        <TextField
                          fullWidth
                          label="Taxable Amount"
                          margin="normal"
                          name="taxable_amount"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            const regex = /^\d*(\.\d{0,2})?$/;
                            if (regex.test(value.toString())) {
                              setFieldValue("taxable_amount", value);
                            }
                          }}
                          value={values.taxable_amount}
                          type="text"
                          variant="outlined"
                          error={Boolean(
                            touched.taxable_amount && errors.taxable_amount
                          )}
                          helperText={
                            touched.taxable_amount && errors.taxable_amount
                          }
                        />
                      </Grid>

                      <Grid item md={12}>
                        <TextField
                          fullWidth
                          label="Category"
                          name="category_id"
                          select
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.category_id}
                          margin="normal"
                          variant="outlined"
                          error={Boolean(
                            touched.category_id && errors.category_id
                          )}
                          helperText={touched.category_id && errors.category_id}
                        >
                          {categories.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ServicesDetails;
