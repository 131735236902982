import { Grid } from "@mui/material";
import React from "react";
import Lottie from 'react-lottie';
// import animation from '../../assets/FernWood_Loading_color-1.json'
import animation from '../../assets/FernWood_Loading_color2_new.json'

export default function CenterLoader({ minHeight }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: minHeight,
      }}
      // style={{ width: '150px', height: '150px', margin: '0 auto',minHeight: minHeight, }}
    >
      {/* <img
        src="/img/logo/loading.gif"
        style={{ height: 100, alignSelf: "center" }}
      /> */}
    <Lottie options={defaultOptions }   style={{ height: 100, alignSelf: "center" }} />
    </Grid>
  );
}
