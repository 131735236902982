import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from "@material-ui/core";
import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import Cancelbtn from "src/components/common/Cancelbtn";
import Rating from "@material-ui/lab/Rating";

const AddDocumentFormSchema = Yup.object().shape({
  // user_id: Yup.string()
  //   .min(2, Messages.TOO_SHORT)
  //   .required(Messages.REQUIRED),
  user_id: Yup.string().required(Messages.REQUIRED),
  first_name: Yup.string().required(Messages.FIELD_REQUIRED),
  middle_name: Yup.string(),
  last_name: Yup.string().required(Messages.FIELD_REQUIRED),
  // star: Yup.string().required(Messages.REQUIRED),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  selectControlFile: {
    minWidth: "49%",
    margin: "15px 0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const ReviewEditDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { mode, formValues, userlists, setSnackbar } = props;
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddDocumentFormSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/review/send_review_mail";
        } else {
          method = "PATCH";
          url = "admin/review/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/reviews");
            } else if (status === 400) {
              if (data.name) {
                actions.setFieldError("name", data.name[0]);
              }
            }
            setSnackbar(true, status, message);
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader={
                  "The information can be " + mode.toLowerCase() + " review"
                }
                title={mode + " Review"}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {/* <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="user_id">Users</InputLabel>
                      <Select
                        labelId="user_id"
                        id="user_id"
                        label="Select User"
                        name="user_id"
                        value={values.user_id}
                        onChange={(e) => {
                          setFieldValue("user_id", e.target.value);
                        }}
                        error={Boolean(touched.user_id && errors.user_id)}
                        fullWidth
                        disabled={values.id !== undefined}
                      >
                        <MenuItem value="">
                          <em>Select User</em>
                        </MenuItem>

                        {userlists.map((user_lists, index) => (
                          <MenuItem key={index} value={user_lists.id}>
                            {user_lists.first_name +
                              " " +
                              user_lists.last_name +
                              "(" +
                              user_lists.email +
                              ")"}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.user_id && errors.user_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.user_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid> */}
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      margin="normal"
                      fullWidth
                      helperText={touched.email && errors.email}
                      value={values.email}
                      disabled={values.id !== undefined}
                    />
                  </Grid>{" "}
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.first_name && errors.first_name)}
                      margin="normal"
                      fullWidth
                      disabled
                      rows={3}
                      helperText={touched.first_name && errors.first_name}
                      value={values.first_name}
                    />
                  </Grid>{" "}
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="middle_name"
                      label="Middle Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.middle_name && errors.middle_name)}
                      margin="normal"
                      fullWidth
                      disabled
                      rows={3}
                      helperText={touched.middle_name && errors.middle_name}
                      value={values.middle_name}
                    />
                  </Grid>{" "}
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="last_name"
                      label="Last Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.last_name && errors.last_name)}
                      margin="normal"
                      fullWidth
                      disabled
                      rows={3}
                      helperText={touched.last_name && errors.last_name}
                      value={values.last_name}
                    />
                  </Grid>
                  {/* <Grid item md={12} xs={12}>
                    <div className="review_rating">
                      <InputLabel id="star">Rating</InputLabel>
                      <Rating
                        id="star"
                        name="star"
                        defaultValue={values.star ? values.star : ""}
                        precision={0.5}
                        onChange={(e) => {
                          setFieldValue("star", e.target.value);
                        }}
                        // value={values.star}
                        // error={Boolean(touched.star && errors.star)}
                      />
                      {Boolean(touched.star && errors.star) ? (
                        <FormHelperText className="Mui-error">
                          {errors.star}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </Grid> */}

                  <Grid item xs={12} md={12}>
                    <InputLabel className="feedback-filed" id="star">
                      Rating:
                    </InputLabel>
                    <Select
                      id="star"
                      name="star"
                      variant="outlined"
                      margin="normal"
                      disabled
                      rows={3}
                      fullWidth
                      value={values.star}
                      onChange={(e) => {
                        setFieldValue("star", e.target.value);
                      }}
                      className="rating"
                    >
                      <MenuItem value={5}>5. Excellent</MenuItem>
                      <MenuItem value={4}>4. Very Good</MenuItem>
                      <MenuItem value={3}>3. Neutral</MenuItem>
                      <MenuItem value={2}>2. Needs Improvement</MenuItem>
                      <MenuItem value={1}>1. Poor</MenuItem>
                    </Select>
                    {Boolean(touched.star && errors.star) ? (
                      <FormHelperText className="Mui-error">
                        {errors.star}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="remark"
                      label="Review"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.remark && errors.remark)}
                      margin="normal"
                      fullWidth
                      multiline
                      disabled
                      rows={3}
                      helperText={touched.remark && errors.remark}
                      value={values.remark}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className="confirm-feedback">
                    <FormControlLabel
                      className="feedback-info"
                      control={
                        <Checkbox
                          checked={values.is_allow_public_shared}
                          disabled
                          onChange={(e) =>
                            setFieldValue(
                              "is_allow_public_shared",
                              e.target.checked
                            )
                          }
                          color="primary"
                        />
                      }
                      label="I consent to having my review shared publicly (optional)"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {/* <Cancelbtn /> */}
                <Button
                  style={{ marginRight: "7px" }}
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
                {/* <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button> */}
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReviewEditDetails;
