import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Page from "src/components/Page";
import TabPanel from "src/components/TabPanel";
//importing tab views
import PreQuestView from "./PrequestView";
import PostQuestView from "./PostQuestView";
import PackageInfoView from "../PackageInfoView";
import PaymentView from "../PaymentView";
import UserRegister from "../UserRegister";
import ExtraPaymentView from "../MailesChargesPaymentView";
import Status_f from "../../Fernwood_counsellor_v1/Status_f";
import Contact_f from "../../Fernwood_counsellor_v1/Contact_f";
import Email_f from "../../Fernwood_counsellor_v1/Email_f";
import History_f from "../../Fernwood_counsellor_v1/History_f";
import Packages_payments_f from "../../Fernwood_counsellor_v1/Packages_payments_f";
import Shipping from "../../Fernwood_counsellor_v1/Shipping";
import { Link } from "react-router-dom";
import { getItem, setItem } from "src/services/Helper";
import { call } from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";
import PackageInfoView_2 from "../PackageInfoView_2";
// import { PostQuestView } from "../PostQuestView";
// import WesternQuestView from "../PostQuestView/WesternQa.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(3),
  },
  loadingAnimation: {
    color: "gray",
    fontStyle: "italic",
    display: "inline-block",
    "& span": {
      opacity: 0,
      animation: "$blink 1.5s infinite",
    },
    "& span:nth-child(1)": {
      animationDelay: "0s",
    },
    "& span:nth-child(2)": {
      animationDelay: "0.5s",
    },
    "& span:nth-child(3)": {
      animationDelay: "1s",
    },
  },
  "@keyframes blink": {
    "0%": { opacity: 0 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
}));
const LoadingAnimation = () => (
  <span className={useStyles().loadingAnimation}>
    <span>Loading</span>
    <span>.</span>
    <span>.</span>
    <span>.</span>
  </span>
);

export default function CustomAddApplicationView() {
  const classes = useStyles();
  let { id } = useParams();

const [refundStatus, setRefundStatus] = useState(false)
const [adjustPayment, setAdjustPayment] = useState(false)

  const [applicationId, setApplicationId] = useState("");
  const [docusign_url, setDocusign_url] = useState("");
  const [allButtonDisable, setAllButtonDisable] = useState(false);
  const [activeTab, setActiveTab] = useState("step8");
  const [disabledTab, setDisabledTab] = useState([
    // "step1",
    "step2",
    "step3",
    "step4",
    "step5",
    "step6",
    "step7",
    "step8",
    "step9",
    "step10",
    "step11",
    "step12",
    "step13",
  ]);
  const [isExtraMilesChargesApply, setIsExtraMilesChargesApply] = useState(
    false
  );
  const [isExtraMilesPaymentDone, setIsExtraMilesPaymentDone] = useState(false);

  const [isPreQuest, setisPreQuest] = useState(false);
  const [token, setToken] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [isLoading, setLoading] = useState(true);

  let breadcrumb_text = "Add";
  if (id !== undefined && id !== null) {
    breadcrumb_text = "Edit";
    localStorage.setItem("rand_token", id);
  }
  const breadUrlList = [
    { name: "Home", url: "/dashboard" },
    { name: "Application List", url: "/app/counselors" },
    { name: `${breadcrumb_text} Application`, url: "/" },
  ];

  const tabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const checkToken = async () => {
    let token = await getItem("rand_token");
    if (token === null || token === "undefined") {
      await setItem(
        "rand_token",
        Math.random()
          .toString(36)
          .substring(2)
      );
      return await getItem("rand_token");
    } else {
      return token;
    }
  };

  const getPreQueData = async () => {
    let user_id = "";
    let flag = "";
    if (id === undefined) {
      user_id = localStorage.getItem("rand_token");
    } else {
      user_id = id;
    }

    try {
      const response = await call(
        "POST",
        "admin/front_user_filled_pre_qnr_get",
        { device_id: user_id }
      );

      if (id === undefined) {
        setItem("isPreneed", response.data.isPreneed ? 1 : 0);
      } else {
        setItem("isPreneed_" + id, response.data.isPreneed ? 1 : 0);
      }

      setItem("isPostQuest", response.data.isPostQuest ? 1 : 0);

      setisPreQuest(response.data.isPreQuest);
      setApplicationId(response.data.app_code);
      setDocusign_url(response.data.docusign_url);
      if (response.data.app_status === 1 || response.data.app_status === 2) {
        setAllButtonDisable(true);
      }

      if (response.data.editDisableTab === 0) {
        setDisabledTab([
          "step2",
          "step3",
          "step4",
          "step5",
          "step6",
          "step7",
          "step12",
          "step13",
        ]);
        setActiveTab("step8");
      } else if (response.data.editDisableTab === 1) {
        setDisabledTab([
          "step3",
          "step4",
          "step5",
          "step6",
          "step7",
          "step12",
          "step13",
        ]);
        setActiveTab("step2");
      } else if (response.data.editDisableTab === 2) {
        setDisabledTab([
          "step2",
          "step4",
          "step5",
          "step6",
          "step7",
          "step12",
          "step13",
        ]);
        setDisabledTab("step3");
      } else if (response.data.editDisableTab === 3) {
        setDisabledTab([
          "step2",
          "step3",
          "step5",
          !response.data.isPreneed ? "step6" : "",
          "step7",
          "step8",
          "step9",
          "step10",
          "step11",
          "step12",
          "step13",
        ]);
        // if (response.data.isPreneed) {
        //   setActiveTab("step6");
        // } else {
        setActiveTab("step4");
        // }
      } else if (response.data.editDisableTab === 4) {
        setDisabledTab([
          "step2",
          "step3",
          "step4",
          !response.data.isPreneed ? "step6" : "",
          "step7",
          "step13",
        ]);

        setActiveTab("step5");
      } else if (response.data.editDisableTab === 5) {
        if (response.data.isExtraMilesChargesApply) {
          setDisabledTab([
            "step2",
            "step3",
            "step4",
            !response.data.isPreneed ? "step6" : "",
            !response.data.isExtraMilesChargesApply ? "step7" : "",
            "step13",
          ]);
        } else {
          setDisabledTab([
            "step2",
            "step3",
            "step4",
            !response.data.isPreneed ? "step6" : "",
            !response.data.isExtraMilesChargesApply ? "step7" : "",
          ]);
        }
        response.data.isExtraMilesChargesApply
          ? setActiveTab("step7")
          : setActiveTab("step8");
      } else if (response.data.editDisableTab === 6) {
        setDisabledTab([
          "step2",
          "step3",
          "step4",
          // "step5",
          !response.data.isPreneed ? "step6" : "",
          "step7",
        ]);

        // response.data.isPreQuest !== 1 ? setActiveTab("step1") : setActiveTab("step8");

        setActiveTab("step8"); //dev prosess use
      }

      setIsExtraMilesChargesApply(response.data.isExtraMilesChargesApply);
      setIsExtraMilesPaymentDone(response.data.editDisableTab === 6);
    } catch (e) {
      console.log(e);
    }
  };

  const getTheme = async () => {
    await call("get", "get_theme/1").then(async ({ data, status }) => {
      setItem("bgImage", data.image);
      setBgImage(data.image);
    });
  };

  const TabList = [
    {
      value: "step8",
      lable: "Status",
      component: (
        <Status_f
          setTabval={setActiveTab}
          setdisableTab={setDisabledTab}
          mode={breadcrumb_text}
          allButtonDisable={allButtonDisable}
        />
      ),
      isDisabled: disabledTab.includes("step8"),
      isHidden: false,
    },
    {
      value: "step9",
      lable: "Contact",
      component: (
        <Contact_f
          setTabval={setActiveTab}
          allButtonDisable={allButtonDisable}
          setdisableTab={setDisabledTab}
          mode={breadcrumb_text}
        />
      ),
      isDisabled: disabledTab.includes("step9"),
      isHidden: false,
    },

    {
      value: "step10",
      lable: "Email",
      component: <Email_f setTabval={setActiveTab} mode={breadcrumb_text} />,
      isDisabled: disabledTab.includes("step10"),
      isHidden: false,
    },

    {
      value: "step11",
      lable: "History",
      component: <History_f setTabval={setActiveTab} mode={breadcrumb_text} />,
      isDisabled: disabledTab.includes("step11"),
      isHidden: false,
    },
    {
      value: "step1",
      lable: "Pre Questionnaire",
      component: (
        <PreQuestView
          setApplicationId={setApplicationId}
          setDocusign_url={setDocusign_url}
          setAllButtonDisable={setAllButtonDisable}
          setActiveTab={setActiveTab}
          setdisableTab={setDisabledTab}
          setIsExtraMilesChargesApply={setIsExtraMilesChargesApply}
          setIsExtraMilesPaymentDone={setIsExtraMilesPaymentDone}
          getPreQueData={getPreQueData}
          isPreQuest={isPreQuest}
          token={token}
          bgImage={bgImage}
          mode={breadcrumb_text}
        />
      ),
      isDisabled: disabledTab.includes("step1"),
      isHidden: false,
    },
    {
      value: "step2",
      lable: "User Register",
      component: (
        <UserRegister
          setTabval={setActiveTab}
          setdisableTab={setDisabledTab}
          mode={breadcrumb_text}
        />
      ),
      isDisabled: disabledTab.includes("step2"),
      isHidden: !disabledTab.includes("step12") ? true : false,
    },
    {
      value: "step3",
      lable: "Add Services",
      component: (
        <PackageInfoView_2
          setTabval={setActiveTab}
          setdisableTab={setDisabledTab}
          mode={breadcrumb_text}
          refundStatus={refundStatus}
          adjustPayment={adjustPayment}
        />
      ),
      // in this both uncomment when flow complete 
      isDisabled: disabledTab.includes("step3"),
      isHidden: !disabledTab.includes("step12") ? true : false, 
    },
    {
      value: "step6",
      lable: "Great Western Question",
      component: (
        <PostQuestView
          setTabval={setActiveTab}
          setdisableTab={setDisabledTab}
          activeTab={activeTab}
          mode={breadcrumb_text}
        />
      ),
      isDisabled: disabledTab.includes("step6"),
      isHidden: false,
    },
    {
      value: "step4",
      lable: "Payment",
      component: (
        <PaymentView setTabval={setActiveTab} mode={breadcrumb_text} />
      ),
      isDisabled: disabledTab.includes("step4"),
      isHidden: !disabledTab.includes("step12") ? true : false,
    },
    {
      value: "step12",
      lable: "PACKAGES & PAYMENTS",
      component: (
        <Packages_payments_f setTabval={setActiveTab} mode={breadcrumb_text}
         refundStatus={refundStatus} setRefundStatus={setRefundStatus} 
         adjustPayment={adjustPayment} setAdjustPayment={setAdjustPayment} 
         />
      ),
      isDisabled: disabledTab.includes("step4") ? false : true,
      isHidden: disabledTab.includes("step12") ? true : false,
    },
    {
      value: "step5",
      lable: "Post Questionnaire",
      component: (
        <PostQuestView
          setTabval={setActiveTab}
          activeTab={activeTab}
          mode={breadcrumb_text}
        />
      ),
      isDisabled: disabledTab.includes("step5"),
      isHidden: false,
    },
    {
      value: "step7",
      lable: "Extra Miles Charges",
      component: (
        <ExtraPaymentView setTabval={setActiveTab} mode={breadcrumb_text} />
      ),
      isDisabled: disabledTab.includes("step7"),
      isHidden: isExtraMilesChargesApply
        ? isExtraMilesPaymentDone
          ? true
          : false
        : true,
    },
    {
      value: "step13",
      lable: "DELIVERY",
      component: <Shipping setTabval={setActiveTab} mode={breadcrumb_text} />,
      isDisabled: disabledTab.includes("step13"),
      isHidden: false,
    },
  ];

  useEffect(() => {
    setLoading(true);
    checkToken().then(async (token) => {
      setToken(token);
      await getTheme().then(async () => {
        await getPreQueData().then(async () => {
          setLoading(false);
        });
      });
    });
  }, []);

  if (isLoading) {
    return <ScreenLoader />;
  } else {
    return (
      <Page title="Application" className={classes.page}>
        <Container maxWidth={false}>
          <Grid
            item
            mb={3}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h3" component="h3">
              APPLICATION NO.:
              {docusign_url ? (
                <>
                  <Link
                    to={docusign_url}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    {applicationId}
                  </Link>{" "}
                  <spna style={{ color: "#777", fontStyle: "italic" ,fontSize: "medium"}}>
                    (Click to open in DocuSign)
                  </spna>
                </>
              ) : (
                <>{applicationId ? applicationId : <LoadingAnimation />}</>
              )}
            </Typography>
            <BreadcrumbsAdmin urlList={breadUrlList} />
          </Grid>
          <Paper className={classes.root}>
            <Tabs
              value={activeTab}
              onChange={tabChange}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable auto tabs example"
            >
              {TabList.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab.value}
                  label={tab.lable}
                  disabled={tab.isDisabled} //in this line use , working with active and nonactive tabes
                  style={
                    tab.isHidden ? { display: "none" } : { display: "block" }
                  }
                  {...a11yProps(tab.value)}
                />
              ))}
            </Tabs>
          </Paper>
          <Grid>
            {TabList.map((tab, index) => (
              <TabPanel value={activeTab} index={tab.value} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Grid>
        </Container>
      </Page>
    );
  }
}
