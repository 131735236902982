import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import "react-phone-input-2/lib/material.css";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import * as Messages from "src/constants/Messages/index";
import CustomFaxInput from "src/components/common/CustomFaxInput";
import AutoSuggessionField from "src/components/common/AutoSuggessionField";
import TextMaskCustom from "src/components/TextMaskCustom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
}));

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(50, "First Name must be at most 50 characters")
    .required(Messages.REQUIRED),
  last_name: Yup.string()
    .max(50, "Last Name must be at most 50 characters")
    .required(Messages.REQUIRED),
  middle_name: Yup.string()
    .max(50, "Middle Name must be at most 50 characters")
    // .required(Messages.REQUIRED)
    ,
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  mobile: Yup.string()
    .required(Messages.REQUIRED)
    .min(5, Messages.REQUIRED),
  designation: Yup.string().when("company", {
    is: (company) => /^(0|[1-9][0-9]*)$/.test(company),
    then: Yup.string().required(Messages.REQUIRED),
  }),
  address: Yup.string().max(200, "Address must be at most 200 characters"),
  notes: Yup.string().max(500, "Notes must be at most 500 characters"),
});

const AddContactForm = (props) => {
  const classes = useStyles();
  const { id, formValues, updateUser, contactCompany, staffPositions } = props;
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);

  const initialValues = {
    user_id: id,
    first_name: "",
    //  formValues.first_name,
    last_name: "",
    middle_name: "",
    //  formValues.last_name,
    email: "",
    //  formValues.email,
    mobile: "",
    //  formValues.mobile,
    fax: "",
    // formValues.fax,
    company: "",
    // formValues.company,
    designation: [],
    //  formValues.designation,
    address: "",
    notes: "",
    // formValues.address,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AddUserSchema}
      onSubmit={async (values, actions) => {
        if (checkPhoneNumber !== 1) {
          await updateUser(values, actions);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              {/* <CardHeader
                subheader="The information can be edited"
                title={id !== undefined ? "Edit Contact" : "Add Contact"}
              /> */}
              {/* <Divider /> */}
              <CardContent>
                <Grid container spacing={3}>
                  {/* <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="User Id"
                      name="user_id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={id}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.user_id && errors.user_id)}
                      helperText={touched.user_id && errors.user_id}
                      hidden
                    />
                  </Grid> */}
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      error={Boolean(touched.first_name && errors.first_name)}
                      margin="normal"
                      helperText={touched.first_name && errors.first_name}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      label="Middle Name"
                      name="middle_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.middle_name}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.middle_name && errors.middle_name)}
                      helperText={touched.middle_name && errors.middle_name}
                    />
                  </Grid>

                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      margin="normal"
                      variant="outlined"
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      type="email"
                      variant="outlined"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="normal"
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      variant="outlined"
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                    {/* <CustomPhoneInput
                      name={"mobile"}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCheckPhoneNumber={setCheckPhoneNumber}
                    /> */}
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      label="Fax Number"
                      margin="normal"
                      name="fax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.fax}
                      variant="outlined"
                      error={Boolean(touched.fax && errors.fax)}
                      helperText={touched.fax && errors.fax}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                    {/* <CustomFaxInput
                      name="fax"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <AutoSuggessionField
                      idKey={"id"}
                      labelKey={"company"}
                      options={contactCompany}
                      name="company"
                      label="Company"
                      setAddress={setFieldValue}
                      addFieldName={"address"}
                    />
                  </Grid>
                  {/* <Grid item md={6} xs={12}>
                    <AutoSuggessionField
                      idKey={"id"}
                      labelKey={"title"}
                      options={staffPositions}
                      name="designation"
                      label="Staff Position"
                    />
                  </Grid> */}
 
  {/* {Boolean(touched.designation && errors.designation) && (
    <FormHelperText className="Mui-error">
      {errors.designation}
    </FormHelperText>
  )} */}

                  {/* its use extrnal  */}
                  <Grid item  xs={12}>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel id="select-designation-label">
                        Staff Position
                      </InputLabel>
                      <Select
                        id="select-designation"
                        name="designation"
                        label="Staff Position"
                        // fullWidth
                        error={Boolean(
                          touched.designation && errors.designation
                        )}
                        multiple
                        value={values.designation || []}
                        onChange={(e) => {
                          setFieldValue("designation", e.target.value);
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Placeholder</em>;
                          }
                          return selected.length + " selected";
                        }}
                      >
                        {staffPositions.map((position, index) => (
                          <MenuItem
                            key={`position_${index}`}
                            value={position.id}
                          >
                            <Checkbox
                              checked={
                                values.designation.indexOf(position.id) > -1
                              }
                              onChange={(e) => {
                                const newValue = [...values.designation];
                                if (e.target.checked) {
                                  newValue.push(position.id);
                                } else {
                                  const index = newValue.indexOf(position.id);
                                  newValue.splice(index, 1);
                                }
                                setFieldValue("designation", newValue);
                              }}
                            />
                            <ListItemText primary={position.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      name="address"
                      label="Address"
                      variant="outlined"
                      margin="normal"
                      placeholder={props.placeholder}
                      multiline
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      onChange={handleChange}
                      // disabled
                      value={values.address}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <TextField
                      fullWidth
                      name="notes"
                      label="notes"
                      variant="outlined"
                      margin="normal"
                      placeholder={props.placeholder}
                      multiline
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      onChange={handleChange}
                      // disabled
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {Object.keys(touched).some((field) => touched[field]) &&
                      Object.keys(errors).some(
                        (field) => touched[field] && errors[field]
                      ) && (
                        <Typography variant="body2" color="error">
                          Fill in a valid value for all required fields.
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                {/* <Cancelbtn /> */}
                <Button
                  style={{ marginRight: "7px" }}
                  variant="contained"
                  onClick={() => props.handleModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default AddContactForm;
