import * as yup from "yup";
export const getSchema = (type, question_id = null) => {
  switch (type) {
    case 3:
      return yup.object().shape({
        answer: yup
          .array()
          .required("Required") // these constraints are shown if and only if inner constraints are satisfied
          .min(1, "Required"),
      });
      break;
    case 4:
      return yup
        .object()
        .shape({
          answer: yup.string().required("Required").nullable(),
        })
        .required("Required")
        .nullable();
      break;
    case 5:
      return yup.object().shape({
        answer: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 500 character allowed.",
            (val) => val && val.toString().length <= 500
          ),
      });
      break;
    case 9:
      return yup.object().shape({
        answer: yup.string().email("Invalid email format").required("Required"),
      });
      break;
    case 10:
      return yup.object().shape({
        answer: yup.string().required("Required"),
      });
      break;
    case 11:
      if (question_id === 13) {
        return yup.object().shape({
          answer: yup
            .number()
            .positive()
            .integer()
            .min(1)
            .max(5)
            .required("Required"),
        });
      } else {
        // old:
        // return yup.object().shape({
        //   answer: yup
        //     .number()
        //     // .transform(parseInt)
        //     .min(0)
        //     .required("Required")
        //     .test(
        //       "len",
        //       "Maximum 20 character allowed.",
        //       (val) => val.toString().length <= 20
        //     ),
        // });

        return yup.object().shape({
          answer: yup
            .number()
            .min(0)
            .required("Required")
            .test("len", "Maximum 20 characters allowed.", (val) => {
              // console.log("Value of val:", val); // Log val to see its value
              if (val !== undefined && !isNaN(val)) {
                return val.toString().length <= 20;
              }
              return false;
            }),
        });
      }
      break;
    case 13:
      // return yup.object().shape({
      //   answer: yup
      //     .date()
      //     .nullable()
      //     .transform((originalValue, originalObject) => {
      //       // Transform empty string to null to handle cases where the date is not provided
      //       return originalValue === "" ? null : originalValue;
      //     })
      //     .test(
      //       "dob",
      //       "The date should be less than 18 years ago",
      //       function (value) {
      //         if (!value) {
      //           // If the date is null or undefined, validation passes
      //           return true;
      //         }

      //         const dob = new Date(value);
      //         const eighteenYearsAgo = new Date();
      //         eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

      //         return dob <= eighteenYearsAgo;
      //       }
      //     )
      //     .required("Required"),
      // });
      return yup.object().shape({
        answer: yup
          .string()
          .required("Date is required")
          .nullable()
          .test("valid-date", "Invalid date", function (value) {
            if (!value) return true; // Skip validation if value is null or empty

            const parsedDate = new Date(value);
            return !isNaN(parsedDate); // Check if the parsed date is valid
          })
          .test(
            "minimum-age",
            "You must be at least 18 years old",
            function (value) {
              if (!value) return true; // Skip validation if value is null or empty

              const parsedDate = new Date(value);
              const minAgeDate = new Date();
              minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);

              return parsedDate <= minAgeDate; // Check if the date is at least 18 years ago
            }
          )
          .test("max-date", "Date cannot be in the future", function (value) {
            if (!value) return true; // Skip validation if value is null or empty

            const parsedDate = new Date(value);
            return parsedDate <= new Date(); // Check if the date is not in the future
          }),
      });

    //  case 13:
    // return yup.object().shape({
    //   answer: yup
    //     // .date()
    //     .string()
    //     .matches(/^\d{2}[./-]\d{2}[./-]\d{4}$/, "Invalid format")
    //     .nullable()
    //     .test(
    //       "dob",
    //       "The date should be less than 18 year",
    //       function (value, ctx) {
    //         const dob = new Date(value);
    //         const validDate = new Date();
    //         const valid = validDate.getFullYear() - dob.getFullYear() >= 18;
    //         return !valid ? ctx.createError() : valid;
    //       }
    //     )
    //     .required("Required"),
    // });
    case 14:
      return yup.object().shape({
        firstname: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 100 character allowed.",
            (val) => val && val.toString().length <= 100
          ),
        middlename: yup
          .string()
          // .test(
          //   "len",
          //   "Maximum 100 character allowed.",
          //   (val) => val && val.toString().length <= 100
          // )
          .max(100, "Maximum 100 characters allowed.")
          .nullable(),
        lastname: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 100 character allowed.",
            (val) => val && val.toString().length <= 100
          ),
      });

    case 15:
      return yup.object().shape({
        answer: yup
          .number()
          .positive()
          .integer()
          .min(1)
          .max(100)
          .required("Required"),
      });
    // case 17:
    //   return yup.object().shape({
    //     answer: yup
    //       .number()
    //       .positive()
    //       .integer()
    //       .required("Required")
    //       .test(
    //         "len",
    //         "Invalid ssn no",
    //         (val) => val && val.toString().length === 9
    //       ),

    case 17:
      return yup.object().shape({
        answer: yup
          .string()
          .required("Required")
          .test("len", "Invalid ssn no", (val) => /^(0|\d{9})$/.test(val)),
      });

    case 18:
      return yup.object().shape({
        firstname: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 100 character allowed.",
            (val) => val && val.toString().length <= 100
          ),
        middlename: yup
          .string()
          // .test(
          //   "len",
          //   "Maximum 100 character allowed.",
          //   (val) => val && val.toString().length <= 100
          // )
          .max(100, "Maximum 100 characters allowed.")
          .nullable(),
        lastname: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 100 character allowed.",
            (val) => val && val.toString().length <= 100
          ),
      });

    case 6:
      return yup.object().shape({
        answer: yup.string().required("Required").nullable(),
      });
    default:
      return yup.object().shape({
        answer: yup
          .string()
          .required("Required")
          .test(
            "len",
            "Maximum 100 character allowed.",
            (val) => val && val.toString().length <= 100
          )
          .nullable(),
      });
      break;
  }
};
