import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  Chip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import * as Http from "src/services/Http";
import * as Messages from "src/constants/Messages/index";
import Cancelbtn from "src/components/common/Cancelbtn";
import Rating from "@material-ui/lab/Rating";
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxOutlineBlankSharp,
} from "@material-ui/icons";

const AddDocumentFormSchema = Yup.object().shape({
  // user_id: Yup.string()
  //   .min(2, Messages.TOO_SHORT)
  //   .required(Messages.REQUIRED),
  user_id: Yup.string().required(Messages.REQUIRED),
  // star: Yup.string().required(Messages.REQUIRED),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectControl: {
    minWidth: "100%",
    margin: "15px 0",
  },
  selectControlFile: {
    minWidth: "49%",
    margin: "15px 0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const ReviewFormDetails = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { mode, formValues, userlists, setSnackbar } = props;
  return (
    <Formik
      initialValues={formValues}
      validationSchema={AddDocumentFormSchema}
      onSubmit={async (values, actions) => {
        let method = "";
        let url = "";
        if (formValues.id === undefined) {
          method = "POST";
          url = "admin/review/send_review_mail";
        } else {
          method = "PATCH";
          url = "admin/review/" + formValues.id;
        }
        await Http.call(method, url, values).then(
          async ({ status, message, data }) => {
            if (status === 200) {
              navigate("/app/reviews");
            } else if (status === 400) {
              if (data.name) {
                actions.setFieldError("name", data.name[0]);
              }
            }
            setSnackbar(true, status, message);
          }
        );
        actions.setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => {
        return (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                subheader={
                  "The information can be " + mode.toLowerCase() + " review"
                }
                title={mode + " Review"}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {/* <Grid item md={12} xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="user_id">Users</InputLabel>
                      <Select
                        labelId="user_id"
                        id="user_id"
                        label="Select User"
                        name="user_id"
                        value={values.user_id}
                        onChange={(e) => {
                          setFieldValue("user_id", e.target.value);
                        }}
                        error={Boolean(touched.user_id && errors.user_id)}
                        fullWidth
                        disabled={values.id !== undefined}
                      >
                        <MenuItem value="">
                          <em>Select User</em>
                        </MenuItem>

                        {userlists.map((user_lists, index) => (
                          <MenuItem key={index} value={user_lists.id}>
                            {user_lists.first_name +
                              " " +
                              user_lists.last_name +
                              "(" +
                              user_lists.email +
                              ")"}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.user_id && errors.user_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.user_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid> */}

                  {/* old filed with checkbox */}
                  {/* <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                    >
                      <InputLabel id="user_id">Select Users</InputLabel>
                      <Select
                        labelId="user_id"
                        id="user_id"
                        label="Select Users"
                        name="user_id"
                        value={values.user_id || []}
                        onChange={(e) => {
                          setFieldValue("user_id", e.target.value);
                        }}
                        error={Boolean(touched.user_id && errors.user_id)}
                        fullWidth
                        disabled={values.id !== undefined}
                        multiple
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Placeholder</em>;
                          }
                          return selected.length + " selected";
                        }}
                      >
                        {userlists.map((user, index) => (
                          <MenuItem key={index} value={user.id}>
                            <Checkbox
                              checked={
                                values.user_id &&
                                values.user_id.indexOf(user.id) > -1
                              }
                              onChange={(e) => {
                                const selectedUserId = e.target.value;
                                const selectedIndex = values.user_id.indexOf(
                                  selectedUserId
                                );
                                const newSelected = [...values.user_id];
                                if (selectedIndex === -1) {
                                  newSelected.push(selectedUserId);
                                } else {
                                  newSelected.splice(selectedIndex, 1);
                                }
                                setFieldValue("user_id", newSelected);
                              }}
                            />
                            <ListItemText
                              primary={
                                user.first_name +
                                " " +
                                user.last_name +
                                " (" +
                                user.email +
                                ")"
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(touched.user_id && errors.user_id) ? (
                        <FormHelperText className="Mui-error">
                          {errors.user_id}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid> */}

                  {/* new with autocomplete */}
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      id="user_id"
                      options={userlists.map((option) => ({
                        id: option.id,
                        label: `${option.first_name} ${option.last_name} (${option.email})`,
                      }))}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      value={values.user_id || []}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "user_id",
                          newValue.map((val) =>
                            val.label ? val : { id: "", label: val }
                          )
                        );
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={option.id || index}
                            label={option.label}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Users"
                          name="user_id"
                          variant="outlined"
                          fullWidth
                          error={Boolean(touched.user_id && errors.user_id)}
                          helperText={touched.user_id && errors.user_id}
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid item md={12} xs={12}>
                    <div className="review_rating">
                      <InputLabel id="star">Rating</InputLabel>
                      <Rating
                        id="star"
                        name="star"
                        defaultValue={values.star ? values.star : ""}
                        precision={0.5}
                        onChange={(e) => {
                          setFieldValue("star", e.target.value);
                        }}
                        // value={values.star}
                        // error={Boolean(touched.star && errors.star)}
                      />
                      {Boolean(touched.star && errors.star) ? (
                        <FormHelperText className="Mui-error">
                          {errors.star}
                        </FormHelperText>
                      ) : null}
                    </div>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="remark"
                      label="Review"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.remark && errors.remark)}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={3}
                      helperText={touched.remark && errors.remark}
                      value={values.remark}
                    />
                  </Grid> */}
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Cancelbtn />
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </Box>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReviewFormDetails;
