import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { setSnackbar } from "src/actions";
import { connect } from "react-redux";

import * as Http from "src/services/Http";
import { useNavigate, useParams } from "react-router";
import EmailDetails from "./EmailDetails";
import Page from "src/components/Page";
import ScreenLoader from "src/components/ScreenLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const Email_f = (props) => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [docuforms, setDocuForms] = useState([]);
  const [Loading, setLoading] = useState(true);

  const { id } = useParams(1);
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else{
    userId = id;
  }

  const navigate = useNavigate();
  const [emailtemplatelist, setEmailtemplist] = useState([]);

  const fetchEmailtemplates_subjectlist = async () => {
    try {
      setLoading(true);
      const response = await Http.call("GET", "admin/email_templates");
      const manualEmailList = response.data.filter((item) => item.type === 1);
      // console.log("list of data::", manualEmailList);
      setEmailtemplist(manualEmailList);
      setLoading(false);
      // if (manualEmailList.length > 0) {
      //   setInitialValues({
      //     ...initialValues,
      //     message: manualEmailList[0].email_message,
      //   });
      // }
      // console.log("subject message::", manualEmailList[0].email_message);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchUserList = async () => {
    try {
      setLoading(true);
      if(userId){
        const response = await Http.call(
          "GET",
          "admin/application/contacts/" + userId
        );

        const filteredUserList = response.data.filter(user => user.email !== null && user.email !== ""  );
        setUserList(filteredUserList);
        // setUserList(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDocumentForms = async () => {
    try {
      setLoading(true);

      const responsedocuforms = await Http.call("GET", "admin/forms");
      await setDocuForms(responsedocuforms.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchEmailtemplates_subjectlist();
    fetchUserList();
    fetchDocumentForms();
  }, []);

  // const addEmailtemplatedetails = async (data) => {
  //   let status = 401;
  //   await Http.call("POST", "admin/email_templates", {
  //     ...data,
  //   }).then(async (response) => {
  //     status = response.status;
  //     setSnackbar(true, response.status, response.message);
  //     if (status === 200 || status === 201) {
  //       navigate("/app/emailtemplate/manual");
  //     }
  //   });
  // };

  const sendEmail = async (updatedValues, actions) => {
    // console.log(values.docFormAttachment.length);
    setLoading(true);
    let formData = new FormData();

    for (let i = 0; i < updatedValues.docFormAttachment.length; i++) {
      formData.append("docFormAttachment[]", updatedValues.docFormAttachment[i]);
    }
    // for (let i = 0; i < updatedValues.docFormName.length; i++) {
    //   formData.append("docFormName[]", updatedValues.docFormName[i]);
    // }

    formData.append("to", updatedValues.to);
    formData.append("cc", updatedValues.cc);
    formData.append("userId", updatedValues.userId);

    formData.append("bcc", updatedValues.bcc);
    formData.append("emailSubject", updatedValues.emailSubject);
    formData.append("emailMessage", updatedValues.emailMessage);
    formData.append("saveAsTemplate", updatedValues.saveAsTemplate);


    await Http.call(
      "POST",
      "admin/sendapplicationemail",
      formData,
      false,
      true
    ).then(async (response) => {
      if (response.status === 200) {
        setLoading(false);
        props.setSnackbar(true, response.status, response.message);
      } else {
        console.error("api doesnt get response");
        props.setSnackbar(true, response.status, response.message);
        actions.setErrors(response.updatedValues);
        setLoading(false);
      }
    });
  };

  if (Loading) {
    return <ScreenLoader />;
  }
  return (
    <Page title="Email" className={classes.root}>
      <EmailDetails
        id={userId}
        userList={userList}
        sendEmail={sendEmail}
        emailtemplatelist={emailtemplatelist}
        docuforms={docuforms}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Email_f);
