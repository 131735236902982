import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Cancelbtn from "src/components/common/Cancelbtn";
import "react-phone-input-2/lib/material.css";
import CustomPhoneInput from "src/components/common/CustomPhoneInput";
import * as Messages from "src/constants/Messages/index";
import CustomFaxInput from "src/components/common/CustomFaxInput";
import AutoSuggessionField from "src/components/common/AutoSuggessionField";
import TextMaskCustom from "src/components/TextMaskCustom";
// import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectControl: {
    minWidth: "100%",
  },
}));

const AddUserSchema = Yup.object().shape({
  first_name: Yup.string().required(Messages.REQUIRED),
  middle_name: Yup.string()
  // .required(Messages.REQUIRED)
  ,
  last_name: Yup.string().required(Messages.REQUIRED),
  email: Yup.string()
    .lowercase()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.REQUIRED),
  mobile: Yup.string()
    .required(Messages.REQUIRED)
    .min(5, Messages.REQUIRED),
  designation: Yup.string().when("company", {
    is: (company) => /^(0|[1-9][0-9]*)$/.test(company),
    then: Yup.string().required(Messages.REQUIRED),
  }),
});

const ContactForm = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate();

  const { id, formValues, updateUser, contactCompany, staffPositions } = props;
  const [checkPhoneNumber, setCheckPhoneNumber] = useState(0);
  // const close = () => navigate(-1);

  const initialValues = {
    first_name: formValues.first_name,
    middle_name: formValues.middle_name,
    last_name: formValues.last_name,
    email: formValues.email,
    mobile: formValues.mobile,
    fax: formValues.fax,
    company: formValues.company,
    designation: formValues.designation,
    address: formValues.address,
  };

  return (
    // <Dialog open onClose={close}>
      <Formik
        initialValues={initialValues}
        validationSchema={AddUserSchema}
        onSubmit={async (values, actions) => {
          if (checkPhoneNumber !== 1) {
            await updateUser(values, actions);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader
                  subheader="The information can be edited"
                  title={id !== undefined ? "Edit Contact" : "Add Contact"}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name="first_name"
                        label="First Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        error={Boolean(touched.first_name && errors.first_name)}
                        margin="normal"
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name="middle_name"
                        label="Middle Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.middle_name}
                        error={Boolean(touched.middle_name && errors.middle_name)}
                        margin="normal"
                        helperText={touched.middle_name && errors.middle_name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        margin="normal"
                        variant="outlined"
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        name="email"
                        disabled={formValues.email === "" || formValues.email === null  ? false : true} 
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        type="email"
                        variant="outlined"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        margin="normal"
                        name="mobile"
                        disabled={formValues.mobile === "" || formValues.mobile === null ? false : true} 
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mobile}
                        variant="outlined"
                        error={Boolean(touched.mobile && errors.mobile)}
                        helperText={touched.mobile && errors.mobile}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                      {/* <CustomPhoneInput
                      name={"mobile"}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCheckPhoneNumber={setCheckPhoneNumber}
                    /> */}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Fax Number"
                        margin="normal"
                        name="fax"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.fax}
                        variant="outlined"
                        error={Boolean(touched.fax && errors.fax)}
                        helperText={touched.fax && errors.fax}
                        InputProps={{
                          inputComponent: TextMaskCustom,
                        }}
                      />
                      {/* <CustomFaxInput
                      name="fax"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    /> */}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AutoSuggessionField
                        idKey={"id"}
                        labelKey={"company"}
                        options={contactCompany}
                        name="company"
                        label="Company"
                        setAddress={setFieldValue}
                        addFieldName={"address"}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AutoSuggessionField
                        idKey={"id"}
                        labelKey={"title"}
                        options={staffPositions}
                        name="designation"
                        label="Staff Position"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name="address"
                        label="Address"
                        variant="outlined"
                        margin="normal"
                        placeholder={props.placeholder}
                        multiline
                        error={Boolean(touched.address && errors.address)}
                        onChange={handleChange}
                        disabled
                        value={values.address}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Cancelbtn />
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Box>
              </Card>
            </form>
          );
        }}
      </Formik>
    // </Dialog>
  );
};

export default ContactForm;
