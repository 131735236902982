import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel from "src/components/TabPanel";
import Results from "./Results";
import * as Http from "src/services/Http";
import { connect, useDispatch, useSelector } from "react-redux";
import ScreenLoader from "src/components/ScreenLoader";
import { setSnackbar } from "src/actions";
import Page from "src/components/Page";
import BreadcrumbsAdmin from "src/components/common/BreadcrumbsAdmin";
import Toolbar from "./Toolbar";
import { SET_USER_ACTIVE_TAB } from "src/actions";
import UserFrontListView from "src/views/User/UserFrontListView";
import VendorListView from "../VendorListView";

const breadUrlList = [
  { name: "Home", url: "/dashboard" },
  { name: "Contacts", url: "/" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  tabs: {
    flexGrow: 1,
    marginTop: "10px",
  },
  paper: {
    width: "40%",
    maxHeight: 435,
  },
}));
function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}
const ContactsListView = (props) => {
  const [userList, setUserList] = useState([]);
  const [Loading, setLoading] = useState(true);
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState("all_contact");

  const dispatch = useDispatch();
  const value = useSelector((state) => state?.session?.userActiveTab);
  const authuser = useSelector((state) => state?.session?.user);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setActiveTab(newValue);
    dispatch({
      type: SET_USER_ACTIVE_TAB,
      payload: { userActiveTab: newValue },
    });
  };

  const fetchUserList = async () => {
    try {
      const response = await Http.call("GET", "admin/lookup_dirs");
      setUserList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const multipleDelete = async (data, isContactDelete) => {
    setLoading(true);
    try {
      await Http.call("POST", "admin/lookup_dirs/delete", {
        id: data,
        iscontactdelete: isContactDelete,
      }).then(async (response) => {
        fetchUserList();
        props.setSnackbar(true, response.status, response.message);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  if (Loading) {
    return <ScreenLoader />;
  }

  return (
    <Page className={classes.root} title="Contacts">
      <Container maxWidth="xl">
        <BreadcrumbsAdmin urlList={breadUrlList} />
        <Toolbar authuser={authuser} />
        <AppBar position="static" color="inherit" className={classes.tabs}>
          <Tabs
            // value={value}
            value={activeTab}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="all_contact"
              label="All Contacts"
              wrapped
              {...a11yProps("all_contact")}
            />
            <Tab
              value="front_user"
              label="Front-end Users"
              {...a11yProps("front_user")}
            />
            <Tab value="vendor" label="Vendors" {...a11yProps("vendor")} />
          </Tabs>
        </AppBar>
        <div>
          <TabPanel  value={activeTab} index="all_contact">
            <Box>
              <Results
                users={userList}
                pageLoading={setLoading}
                fetchUser={fetchUserList}
                multipleDelete={multipleDelete}
                authuser={authuser}
              />
            </Box>
          </TabPanel>
          <TabPanel value={activeTab}  index="front_user">
            <UserFrontListView />
          </TabPanel>
          <TabPanel value={activeTab}  index="vendor">
            <VendorListView />
          </TabPanel>
        </div>
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsListView);
