export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SESSION_SET_IMAGE = "SESSION_SET_IMAGE";
export const SESSION_RESET_IMAGE = "SESSION_RESET_IMAGE";
export const SET_QUESTION_ACTIVE_TAB = "SET_QUESTION_ACTIVE_TAB";
export const SESSION_SET_PACKAGE = "SESSION_SET_PACKAGE";
export const SET_USER_ACTIVE_TAB = "SET_USER_ACTIVE_TAB";

export const login = (token, data) => (dispatch) => {
  dispatch({
    type: SESSION_LOGIN,
    payload: {
      token: token,
      data: data,
    },
  });
};

export const logout = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGOUT,
  });

export const setImage = (image) => (dispatch) =>
  dispatch({
    type: SESSION_SET_IMAGE,
    payload: {
      data: image,
    },
  });

export const resetImage = (image) => (dispatch) =>
  dispatch({
    type: SESSION_RESET_IMAGE,
    payload: {
      data: image,
    },
  });
  export const setPackageSelected = (arg) => (dispatch) => {
    dispatch({
      type: SESSION_SET_PACKAGE,
      payload: {
        data: arg,
      },
    });
  };