import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as Http from "src/services/Http";
import ScreenLoader from "src/components/ScreenLoader";

import { useParams } from "react-router";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  tableCellHeading: {
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "none",
  },
  tableCell: {
    borderBottom: "none",
    padding: theme.spacing(1, 2),
    fontSize: "14px",
  },
  tableCellRed: {
    borderBottom: "none",
    padding: theme.spacing(1, 2),
    fontSize: "14px",
    color: "red",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
}));

export default function PackagesPayments(props) {
  const classes = useStyles();
  const { id } = useParams();
  let userId = "";
  if (id === undefined) {
    userId = localStorage.getItem("rand_token");
    userId = !isNaN(+userId) === false ? undefined : userId;
  } else {
    userId = id;
  }
  const [transactionDetails, setTransactionDetails] = useState({
    transactionId: "",
    status: "",
    purchaseAt: "",
    packageInfo: {
      name: "",
      price: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    products: [],
    services: [],
    deathCertificateInfo: {
      numberOfCertificate: 0,
      totalCertificatePrice: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    extraMileInfromation: {
      address: "",
      price: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    promocodeDetails: {
      promocode: "",
      discountAmount: (0.0).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }),
    },
    totalAmountPaid: (0.0).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }),
  });

  const [Loading, setLoading] = useState(false);
  // console.log("setRefundStatus==>", props.refundStatus);
  // console.log("setadjustPayment==>", props.adjustPayment);


  const handleRefundRedirect = () => {
    props.setTabval("step3");
    props.setRefundStatus(true);
  };
  
  const handleRedirectPayment = () => {
    props.setTabval("step3");
    props.setAdjustPayment(true);
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setLoading(true);
        if (userId) {
          await Http.call("GET", `admin/user/${userId}/subscription`).then(
            async (response) => {
              if (response.status === 200) {
                // const date = new Date(response.data?.purchase_at);
                // const formattedDate = date.toLocaleString("en-US", {
                //   timeZoneName: "short",
                // });

                let products = [];
                let services = [];
                let delivery = [];
                let peacemaker = [];
                response.data?.services.forEach((serviceItem) => {
                  if (["Urns", "Pendant"].includes(serviceItem.category_name)) {
                    products.push({
                      name: serviceItem.service_name,
                      address: serviceItem.address,
                      price:
                        serviceItem.price !== 0
                          ? serviceItem.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : null,

                      quantity: serviceItem.quantity,

                      // price: serviceItem.price.toLocaleString("en-US", {
                      //   style: "currency",
                      //   currency: "USD",
                      // }),
                    });
                  }
                });

                response.data?.services.forEach((serviceItem) => {
                  if (
                    ["Additional Services"].includes(serviceItem.category_name)
                  ) {
                    services.push({
                      name: serviceItem.service_name,
                      address: serviceItem.address,
                      price:
                        serviceItem.price !== 0
                          ? serviceItem.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : null,

                      // price: serviceItem.price.toLocaleString("en-US", {
                      //   style: "currency",
                      //   currency: "USD",
                      // }),
                    });
                  }
                });

                response.data?.services.forEach((serviceItem) => {
                  if (["Delivery"].includes(serviceItem.category_name)) {
                    delivery.push({
                      name: serviceItem.service_name,
                      address: serviceItem.address,
                      price:
                        serviceItem.price !== 0
                          ? serviceItem.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          // : null,
                          : "$0.00",

                      // price: serviceItem.price.toLocaleString("en-US", {
                      //   style: "currency",
                      //   currency: "USD",
                      // }),
                    });
                  }
                });

                response.data?.services.forEach((serviceItem) => {
                  if (["Pacemaker"].includes(serviceItem.category_name)) {
                    peacemaker.push({
                      id: serviceItem.service_id,
                      name: serviceItem.service_name,
                      address: serviceItem.address,
                      quantity: serviceItem.quantity,
                      price:
                        serviceItem.price !== 0
                          ? serviceItem.price.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : null,

                      // price: serviceItem.price.toLocaleString("en-US", {
                      //   style: "currency",
                      //   currency: "USD",
                      // }),
                    });
                  }
                });

                setTransactionDetails({
                  transactionId: response.data?.transaction_id,
                  status: response.data?.status,
                  purchaseAt: response.data?.purchase_at,
                  packageInfo: {
                    name: response.data?.package_name,
                    price:
                      response.data?.package_amount !== 0
                        ? response.data?.package_amount.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )
                        : null,

                    // price: (response.data?.package_amount).toLocaleString(
                    //   "en-US",
                    //   {
                    //     style: "currency",
                    //     currency: "USD",
                    //   }
                    // ),
                  },
                  products: products,
                  services: services,
                  // peacemaker:response.data?.services?.service_id===74 ? peacemaker:null,
                  peacemaker: peacemaker,
                  deathCertificateInfo: {
                    numberOfCertificate: response.data?.certificate_count,
                    totalCertificatePrice:
                      response.data?.certificate_total_price !== 0
                        ? response.data?.certificate_total_price.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )
                        : null,

                    // totalCertificatePrice: (response.data?.certificate_total_price).toLocaleString(
                    //   "en-US",
                    //   {
                    //     style: "currency",
                    //     currency: "USD",
                    //   }
                    // ),
                  },
                  delivery: delivery,
                  extraMileInfromation: {
                    address: response.data?.extra_mile_destination_address,
                    price:
                      response.data?.extra_mile_surcharge !== 0
                        ? response.data?.extra_mile_surcharge.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )
                        : null,
                    quantity: response.data?.extra_mile_qty,
                    // price: (response.data?.extra_mile_surcharge).toLocaleString(
                    //   "en-US",
                    //   {
                    //     style: "currency",
                    //     currency: "USD",
                    //   }
                    // ),
                  },
                  totalTaxAmount: response.data?.total_tax.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  ),
                  promocodeDetails: {
                    promocode: response.data?.promocode,
                    discountAmount:
                      response.data?.discount !== 0
                        ? response.data?.discount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "",
                    // discountAmount: response.data?.discount.toLocaleString(
                    //   "en-US",
                    //   {
                    //     style: "currency",
                    //     currency: "USD",
                    //   }
                    // ),
                  },
                  totalAmountPaid: response.data?.total_amount_payable.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  ),
                });

                //setValues(response.data);
              }
            }
          );
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchSubscription();

    props.setRefundStatus(false);
    props.setAdjustPayment(false);

  }, [userId]);

  if (Loading) {
    return <ScreenLoader />;
  }


  return (
    <Page title="Package & Payment" className={classes.root}>
      <Card>
        <CardHeader title="Package & Payment Information" />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="payment details table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        TRANSACTION ID:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.transactionId
                          ? transactionDetails.transactionId
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        STATUS:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.status
                          ? transactionDetails.status
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        DATE OF PURCHASE:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.purchaseAt
                          ? transactionDetails.purchaseAt
                          : "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        PACKAGE TYPE:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.packageInfo.name
                          ? transactionDetails.packageInfo.name
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails
                          ? transactionDetails.packageInfo.price
                          : ""}
                      </TableCell>
                    </TableRow>

                    {transactionDetails?.products ? (
                      transactionDetails?.products.map((product, index) => (
                        <TableRow key={product?.name}>
                          <TableCell
                            className={classes.tableCellHeading}
                            style={{ textTransform: "uppercase" }}
                          >
                            {index < 1 ? "PRODUCTS:" : ""}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {product?.name} ({product?.quantity})
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {product ? product?.price : ""}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          className={classes.tableCellHeading}
                          style={{ textTransform: "uppercase" }}
                        >
                          {"PRODUCTS:"}
                        </TableCell>
                        <TableCell colSpan={3} className={classes.tableCell}>
                          No product selected
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {""}
                        </TableCell>
                      </TableRow>
                    )}

                    {transactionDetails?.services ? (
                      transactionDetails?.services.map((service, index) => (
                        <TableRow key={service?.name}>
                          <TableCell
                            className={classes.tableCellHeading}
                            style={{ textTransform: "uppercase" }}
                          >
                            {index < 1 ? "SERVICES:" : ""}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service?.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service ? service?.price : ""}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          className={classes.tableCellHeading}
                          style={{ textTransform: "uppercase" }}
                        >
                          {"SERVICES:"}
                        </TableCell>
                        <TableCell colSpan={3} className={classes.tableCell}>
                          No services available
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {""}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        DEATH CERTIFICATES:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.deathCertificateInfo
                          .numberOfCertificate
                          ? transactionDetails.deathCertificateInfo
                              .numberOfCertificate
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails
                          ? transactionDetails.deathCertificateInfo
                              .totalCertificatePrice
                          : ""}
                      </TableCell>
                    </TableRow>

                    {transactionDetails?.delivery ? (
                      transactionDetails?.delivery.map((service, index) => (
                        <TableRow key={service?.name}>
                          <TableCell
                            className={classes.tableCellHeading}
                            style={{ textTransform: "uppercase" }}
                          >
                            {index < 1 ? "DELIVERY:" : ""}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service?.name}
                            <br />
                            {service?.address}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service ? service?.price : ""}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          className={classes.tableCellHeading}
                          style={{ textTransform: "uppercase" }}
                        >
                          {"DELIVERY:"}
                        </TableCell>
                        <TableCell colSpan={3} className={classes.tableCell}>
                          Delivery option not selected
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {""}
                        </TableCell>
                      </TableRow>
                    )}

                    {/* {transactionDetails?.peacemaker ? (
                      transactionDetails?.peacemaker.map((service, index) => (
                        <TableRow key={service?.name}>
                          <TableCell
                            className={classes.tableCellHeading}
                            style={{ textTransform: "uppercase" }}
                          >
                            {index < 1 ? "Implant Disposition:" : ""}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service?.name}({service?.quantity})<br /> 
                            {service?.address}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {service ? service?.price : ""}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : 
                    (
                      <TableRow>
                        <TableCell
                          className={classes.tableCellHeading}
                          style={{ textTransform: "uppercase" }}
                        >
                          {"Pacemaker:"}
                        </TableCell>
                        <TableCell colSpan={3} className={classes.tableCell}>
                          Pacemaker option not selected
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {""}
                        </TableCell>
                      </TableRow>
                    )
                    } */}

                    {transactionDetails?.peacemaker
                      ? transactionDetails.peacemaker.map((service, index) =>
                          service.id === 74 ? (
                            <TableRow key={service?.name}>
                              <TableCell
                                className={classes.tableCellHeading}
                                style={{ textTransform: "uppercase" }}
                              >
                                {index < 1 ? "Implant Disposition:" : ""}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service?.name}({service?.quantity})
                                <br />
                                {service?.address}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {service ? service?.price : ""}
                              </TableCell>
                            </TableRow>
                          ) : null
                        )
                      : null}

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        Taxes:
                      </TableCell>
                      <TableCell className={classes.tableCell}> </TableCell>
                      <TableCell className={classes.tableCell}>
                        {" "}
                        {transactionDetails
                          ? transactionDetails.totalTaxAmount
                          : ""}
                      </TableCell>
                    </TableRow>

                    {transactionDetails.promocodeDetails.promocode ? (
                      <TableRow>
                        <TableCell className={classes.tableCellHeading}>
                          SERVICE DISCOUNT:
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {" "}
                          {transactionDetails.promocodeDetails.promocode
                            ? transactionDetails.promocodeDetails.promocode
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellRed}>
                          {" "}
                          {transactionDetails
                            ? 
                            "-" +
                              transactionDetails.promocodeDetails.discountAmount
                            : ""}
                        </TableCell>
                      </TableRow>
                      ): null
                    }

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        EXTRA MILES CHARGE:
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {transactionDetails.extraMileInfromation.quantity > 0
                          ? transactionDetails.extraMileInfromation.quantity +
                              " Miles"
                          : "0 Miles"}
                          {/* {transactionDetails.extraMileInfromation.quantity > 0
                          ? transactionDetails.extraMileInfromation.quantity ===
                            1
                            ? "0 Miles"
                            : transactionDetails.extraMileInfromation.quantity +
                              " Miles"
                          : ""} */}
                        <br />
                        {transactionDetails.extraMileInfromation.address}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {/* {transactionDetails
                          ? transactionDetails.extraMileInfromation.price
                          : ""} */}

                        {transactionDetails
                          ? transactionDetails.extraMileInfromation.quantity <=
                            20
                            ? "$0.00"
                            : transactionDetails.extraMileInfromation.price
                          : ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.tableCellHeading}>
                        TOTAL AMOUNT:
                      </TableCell>
                      <TableCell className={classes.tableCell}>{""}</TableCell>
                      <TableCell className={classes.tableCellHeading}>
                        {transactionDetails.totalAmountPaid
                          ? transactionDetails.totalAmountPaid
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.buttonContainer}>
                
                <Button variant="contained"                   
                onClick={handleRedirectPayment} color="primary">
                  
                ADJUST PAYMENT
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRefundRedirect}
                >
                  ADJUST REFUND
                </Button>
                <Button variant="contained" color="primary">
                  EDIT APPLICATION DOCUMENTS
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}
